/**
 * @module startGameService
 * @import { RoundStateSignal } from "../models/states.js";
*/

/**
 * 
 * @param {RoundStateSignal} roundState
 */
const stagesCompleted = (roundState) => {
    roundState.setState('stage', 'play');
}

/**
 * @param {RoundStateSignal} roundState
 * @param {String} gameType 
 * @param {String} gameTypeStage 
 */
const selectGameType = (roundState, gameType, gameTypeStage) => {
    roundState.setState('gameType', gameType);
    roundState.setState('stage', gameTypeStage);
}

/**
 * @param {RoundStateSignal} roundState
 * @param {String} gameId
 */
const setReplayGame = (roundState, gameId) => {
    roundState.setState('recycleGame', gameId);
}

/**
 * {Object} startGameService
 * {Function} startGameService.stagesCompleted
 * {Function} startGameService.selectGameType
 * {Function} startGameService.setReplayGame
 */
export const startGameService = { stagesCompleted, selectGameType, setReplayGame };
