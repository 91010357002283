import { html } from "htm/preact";
import  { useState } from "preact/hooks";
import { GameAboutToStart } from "./GameAboutToStart.js";
import { MultiplayerJoinStageService } from "../../services/stagesService.js";
import { effect } from "@preact/signals-core";


export const MultiplayerJoinStage = (props) => {
    const { cancelCb, roundState, stagesCompletedCb } = props;
    const gameAboutToStart = roundState.getState('gameAboutToStart');
    const [currentlyAboutToStart, setCurrentlyAboutToStart] = useState(gameAboutToStart);
    const multiplayerService = new MultiplayerJoinStageService(roundState, stagesCompletedCb);

    let joinId = '';
    const config = { matrixSize: 5, minLengthWord: 4};
    
    const onInput = (e) => {
        joinId = e.currentTarget.value
    }

    const joinMultiplayer = async () => {
        await multiplayerService.joinMultiplayer(config, joinId);
    }

    const multiplayerWatingCb = (multiplayerStatus) => {
        multiplayerService.multiplayerWaiting(multiplayerStatus);
    };

    effect(() => {
        const gameRoomMessage = roundState.getState('gameRoomMessage');
        multiplayerService.readMessageFromRoom(gameRoomMessage, { messageId: 'READY_TO_START', action: multiplayerWatingCb } );
    });

    effect(() => {
        setCurrentlyAboutToStart( roundState.getState('gameAboutToStart'));
    })

    const gameAboutToStartFinishedCb = () => {
        multiplayerService.gameAboutToStartFinished();
    }

    const joiningView = () => {
        return html` 
                <pre> Joining id </pre>
                    <input autofocus type="text" value=${joinId} onInput=${onInput} class="character-input" />
                <div>
                    <button onClick=${joinMultiplayer} role="button" class="button-30 join-multiplayer-button">
                        Join
                    </button>
                    <a href="#" onClick=${cancelCb}>Cancel</a>
                </div>
            `
    }

    const startingGame = () => {
        return html`<${GameAboutToStart} 
            gameAboutToStartFinishedCb=${gameAboutToStartFinishedCb}
            roundState=${roundState}><//>`
    }
    
    return html`
            <div class="game-type-selected-stage">
                ${ ! currentlyAboutToStart ? joiningView() : startingGame() }  
            </div>
            `
}


