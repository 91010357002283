import { signal } from "@preact/signals-core";

/**
 * @import { BoardItems } from "./models.js";
 * @import { Signal} from '@preact/signals-core/' 
*/

export class SignalState {

    /**
     * Constructor
     * @param {SignalStateStruct} state 
     */
    constructor(state) {
        this.state = state;
    }

    /**
     * @param {String} stateName 
     * @returns 
     */
    getState (stateName) {
        try {
            return this.state[stateName].value;
        } catch (err) {
            if (err.name === 'TypeError') {
                console.error(`Property <${stateName}> doesn't exists on object ${this.constructor.name}`);
                throw err;
            }
        } 
    }

    /**
     * @param {String} stateName 
     * @param {any} value 
     * @returns 
     */
    setState (stateName, value) {
        try {
            return this.state[stateName].value = value;
        } catch (err) {
            if (err.name === 'TypeError') {
                console.error(`Property <${stateName}> doesn't exists on object ${this.constructor.name}`);
                throw err;
            }
        } 
    }
}


/**
 * @enum {string} 
 */

export const GameType = {
    SINGLE_PLAYER: 'singleplayer',
    MULTIPLAYER: 'multiplayer'
}

/**
 * @enum {string}
*/
const WaitingStates = {
    STARTED: 'started',
    FINISHED: 'finished'
};


/**
 * @enum {string}
*/
const GameStages = {
    START_SINGLE_PLAYER: 'startSinglePlayer',
    START_MULTIPLAYER: 'startMultiplayer',
    JOIN_MULTIPLAYER: 'joinMultiplayer',
    PLAY: 'PLAY',
    FINISHED: 'finished'
};

class SignalStateStruct {

}

class RoundState extends SignalStateStruct { 

    /** @type { Signal } */  
    gameInstance = signal();
    /** @type { Signal<String> } */  
    gameType = signal();
    /** @type { Signal<GameStages> } */
    stage = signal();
    /** @type { Signal<WaitingStates> } */
    waitingForPlayers = signal('');
    /** @type { Signal<String> } */
    players = signal();
    /** @type { Signal<Boolean> } */
    gameAboutToStart = signal(false);
    /** @type { Signal } */  // MessageType
    gameRoomMessage = signal();
    /** @type { Signal } */
    gameResults = signal(); // ResultType
    /** @type { Signal<String> } */
    playerId = signal();
    /** @type { Signal<String> } */
    recycleGame = signal();

    constructor({gameInstance, gameType, stage, waitingForPlayers: waitingForPlayers, players, gameAboutToStart, gameRoomMessage, gameResults, playerId, recycleGame }) {
        super();
        this.gameInstance = gameInstance;
        this.gameType = gameType; 
        this.stage = stage;
        this.waitingForPlayers = waitingForPlayers;
        this.players = players;
        this.gameAboutToStart = gameAboutToStart;
        this.gameRoomMessage = gameRoomMessage;
        this.gameResults = gameResults;
        this.playerId = playerId;
        this.recycleGame = recycleGame;
    }
}
 

export class RoundStateSignal extends SignalState {

    constructor() {
        const state = new RoundState({
            gameInstance: signal(),
            gameType: signal(),
            stage: signal(),
            waitingForPlayers: signal(''),
            players: signal(), //lazy
            gameAboutToStart: signal(false),
            gameRoomMessage: signal(),
            gameResults: signal(),
            playerId: signal(), // debería estar en otro lado, no es un signal
            recycleGame: signal()
        })
        super(state);
        this.setState('playerId', this.randomPlayerId());
    }

    randomPlayerId () {
        // Returns a random integer from 1 to 100:
        return String(Math.floor(Math.random() * 9999) + 1000); 
    }
}

class GameState extends SignalStateStruct {     
    /**
     * Constructor
     * @param {Object} obj 
     * @param {Object} obj.boardItems
     * @param {Object} obj.selectedBoardItems
     * @param {Object} obj.wordList
     * @param {Signal<boolean>} obj.active
     * @param {Signal<boolean>} obj.finished
     */
    constructor({ boardItems, selectedBoardItems, wordList, active, finished }) {
        super();
        this.boardItems = boardItems;
        this.selectedBoardItems = selectedBoardItems;
        this.wordList = wordList;
        this.active = active;
        this.finished = finished;
    }
    
}

export class GameStateSignal extends SignalState {
    /**
     * Constructor
     * @param {BoardItems} boardItems 
     */
    constructor(boardItems) {
        const gameStateSignals = new GameState({
            boardItems: signal(boardItems),
            selectedBoardItems: signal([]),
            wordList: signal([]),
            active: signal(true),
            finished: signal(false),
        })
        super(gameStateSignals);
    }
}