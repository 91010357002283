import { html } from "htm/preact";
import  {  useState } from "preact/hooks";
import { GameAboutToStart } from "./GameAboutToStart.js";
import { MultiplayerStartService } from "../../services/stagesService.js";
import { effect } from "@preact/signals-core";

/*
    roundState = { gameType: 'multiplayer', 'stage': 'startMultiplayer'}
    muestra el start button que dispara el waiting for players timer.

    roundState = { gameType: 'multiplayer', 'stage': 'startMultiplayer', 'waitingForPlayers': 'started' }
    debería mostrar un cancel button para frenar el timer que acaba de empezar de waitingforplayers.
    El gameInstance debería estar seteado también. Un tiempo de espera de 10 segundos, un timer también se muestra.
    Cada 500ms se chequea si hay otro player joined, durante 10s


    roundState = { gameType: 'multiplayer', 'stage': 'startMultiplayer', 'waitingForPlayers': 'finished' }
    Simula el tiempo de espera agotado, no se unión ningún player.
    El gameInstance debería estar seteado también.

    roundState = { gameType: 'multiplayer', 'stage': 'startMultiplayer', 'waitingForPlayers': 'started',
    players: [{ name: 'lauri'}], multiplayerReadyToStart: true }
    Si waitinfForPlayers es finished, no puede haber players o multiplayerREady.
    Si hay players, tiene que estar multiplayerReadyToStart en true. Puede depender del tamaño de players (computedFN ?)

    multiplayerAboutToStart = true ?

 */

export const MultiplayerStartStage = (props) => {
    const { cancelCb, roundState, stagesCompletedCb } = props;
    const waitingForPlayers = roundState.getState('waitingForPlayers'); //['started', 'finished'];
    const [ currentlyWaitingForPlayer, setCurrentlyWaitingForPlayers ] = useState(waitingForPlayers);
    const gameInstance = roundState.getState('gameInstance');
    const gameAboutToStart = roundState.getState('gameAboutToStart');
    const multiplayerService = new MultiplayerStartService(roundState, stagesCompletedCb);
    
    const config = { matrixSize: 5, minLengthWord: 4};
    
    console.log('repainting <MultiplayerStartStage>');

    const requestNewMultiplayerGame = async () => {
        await multiplayerService.requestNewMultiplayerGame(config);
    }

    const multiplayerWatingCb = async (multiplayerStatus) => {
       await multiplayerService.multiplayerWatingCb(multiplayerStatus);
    }

    const gameAboutToStartFinishedCb = () => {
        multiplayerService.gameAboutToStartFinishedCb();
    }

    const startingGame = () => {
        return  html`<${GameAboutToStart} 
                    gameAboutToStartFinishedCb=${gameAboutToStartFinishedCb}
                    roundState=${roundState}><//>`
    } 

    effect(() => {
        setCurrentlyWaitingForPlayers(roundState.getState('waitingForPlayers'));
    });
    
    const createMultiplayerView = () => {
        return html `
            <Fragment>
                <div>
                    <pre> Your id: ${multiplayerService.randomID} </pre>
                    <button onClick=${requestNewMultiplayerGame} role="button" class="button-30 join-multiplayer-button">
                        Start
                    </button>
                    <a href="#" onClick=${cancelCb}>Cancel</a>
                </div>
                <div class="multiplayer-container">
                    ${ currentlyWaitingForPlayer === 'started' ? 
                        html`<${MultiplayerWaitingForPlayers}
                                multiplayerService=${multiplayerService} 
                                multiplayerWatingCb=${multiplayerWatingCb} 
                                gameInstance=${gameInstance}
                                roundState=${roundState}><//>` 
                        : undefined }
                </div>
            </Fragment>`
    }

    return html`
        <div class="game-type-selected-stage">
            ${ ! gameAboutToStart ? createMultiplayerView() : startingGame() }
        </div>
    `
}

const MultiplayerWaitingForPlayers = (props) => {
    const { multiplayerWatingCb, gameInstance, roundState, multiplayerService } = props;
    const joinId = gameInstance.game.joinId;
    // const gameRoomMessage = roundState.getState('gameRoomMessage');

    console.log('repainting <MultiplayerStatus>');

    // useEffect(async () => {
    //     await addMeToTheRoom({ joinId, playerId: 'lalo' }, roundState);
    // }, []);

    effect(() => {
        const gameRoomMessage = roundState.getState('gameRoomMessage');
        // console.log('Multiplayer gameRoom ', gameRoomMessage);
        multiplayerService.readMessageFromRoom(gameRoomMessage, { messageId: 'READY_TO_START', action: multiplayerWatingCb } );
    });


    return html
        `<div> 
            <pre> game join id: ${joinId} </pre>
            <pre> Waiting for Lauri </pre>
            <div class="flexo-center-justify"> 
                <img src="assets/images/waiting_players.gif"> </img>
            </div>
        </div>`
}