import { GameResult, GameResults } from "../models/models.js";

// class GameResultStats {

//     countValids = 0;
//     /**
//      * Constructor
//      * @param {GameResult} gameResult 
//      */
//     constructor(gameResult) {
//         this.playerId = gameResult.playerId;
//         this.gradedWords = gameResult.gradedWords;
//     }

// }

// /**
//  * 
//  * @param {GameResult} gameResult 
//  * @returns 
//  */
// const countValidWords = (gameResult) => {
//     let countValids = 0;
//     for (const scoredWord of gameResult.gradedWords) {
//         countValids = countValids + ( scoredWord.valid ? 1 : 0 );
//     }
//     return countValids;
// }

export class GameResultsByPlayer {

    /**
     * 
     * @param {GameResult} myPlayer 
     * @param {Array<GameResult>} otherPlayersResults 
     */
    constructor(myPlayer, otherPlayersResults) {
        this.myPlayer = myPlayer;
        this.othersPlayersResults = otherPlayersResults;
    }
}

/**
 * Separate the results based on the player (myPlayer) and counts how many words are graded as valid
 * There are two separate views for the results, one for the current player and the other for the rest in case
 * of multiplayer.
 * @param {GameResults} gameResults 
 * @param {string} playerId 
 * @returns {GameResultsByPlayer}
 */
const prepareGameResults = (gameResults, playerId) => {
    const otherPlayersResults = [];
    let myPlayer;
    for (const result of gameResults.results) {
        if (result.playerId == playerId ) {
            myPlayer = result;
        } else {
            otherPlayersResults.push(result);
        }
    }

    return new GameResultsByPlayer(myPlayer, otherPlayersResults);
}

export const gameResultsService = { prepareGameResults };