import { html } from "htm/preact";
import { RadioOptions } from "../commons/RadioOptions.js";
import { GameConfig } from "../../models/models.js";
import { catalogs } from "../../catalogs/catalogs.js";
import { CoolButon } from "../commons/CoolButton.js";
import { mainStates } from "../../services/mainStates.js";

export const ConfigGame = (props) => {
    const { configFinishedCb, cancelCb } = props;
    const roundState = mainStates.getRoundState();
    const recycleGame = roundState.getState('recycleGame');
    /**@type {GameConfig} */
    const config = new GameConfig(catalogs.defaultGameConfig);
    const timeLengthOptions = catalogs.gameDurationOptions;//[ { value: 10, label: '10 seconds'}, { value: 180, label: '3 minutes'}, { value: 360, label: '5 minutes'}];

    const start = () => {
        if (recycleGame) {
            config.recycleGame = recycleGame;
        }
        configFinishedCb(config);
    }

    const timeOptionSelected = (value) => {
        config.duration = value;
    }

    return html `
        <div class="game-type-selected-stage"> 
            <div class="margin-bottom-1em"> Choose duration </div>
            <div class="margin-bottom-1em">
                <${RadioOptions} options=${timeLengthOptions} defaulOption=${timeLengthOptions[0]} optionSelectedCb=${timeOptionSelected} ><//>
            </div>
            <div>
                <${CoolButon} label="Start" onClickCb=${start} classes="${['cy-start-game']}"><//>
                <mdui-button variant="text" onclick=${cancelCb}>Cancel</mdui-button>
            </div>
        </div>
    `;
}