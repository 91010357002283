import { effect } from "@preact/signals-core";
import { RoundStateSignal } from "../models/states.js";
import { setResultsAvailable } from "./gameResultsService.js";

/**
 * @import { Game } from "../models/models.js"
 */

/**
 * @type Object obj
 * @property RoundStateSignal obj.roundState
 */
const states = {
    roundState: undefined
};

export const mainStates = {
    /**
     * 
     * @param {RoundStateSignal} newRoundState 
     */
    setRoundState: (newRoundState) => {
        states.roundState = newRoundState
        registerSignals();
    },
    getRoundState: () => {
        return states.roundState;
    },
    /**
     * 
     * @returns {Game}
     */
    getGame: () => {
        const gameInstance = states.roundState.getState('gameInstance');
        return gameInstance.game;
    },
    getGameInstance: () => {
        return states.roundState.getState('gameInstance');
    },
    getGameStateSignals: () => {
        const gameInstance = states.roundState.getState('gameInstance');
        return gameInstance.gameStateSignals;
    }
}

const readMessageFromRoom = (gameRoomMessage, actionsByMessages) => {
    const roundState = mainStates.getRoundState();
    if (! gameRoomMessage ) { return };
    console.log('<MainStates> ws message ', gameRoomMessage);
    const { id, data } = gameRoomMessage; 
    const messageAction = actionsByMessages[id];

    if (messageAction) {
        return messageAction(roundState, data);
    }

    return false;
}


const readyToStart = (roundState, data) => {
    console.log('mainStates > readyToStart');
    roundState.setState('gameAboutToStart', true);
    if (data.players) {
        roundState.setState('players', data.players);
    }
    roundState.setState('waitingForPlayers', 'finished');
}

const registerSignals = () => {
    effect(()=>{
        console.log('mainStates > effect()')
        const roundState = mainStates.getRoundState();
        const gameRoomMessage = roundState.getState('gameRoomMessage')
        const actionsByMessages = {
            'RESULTS_AVAILABLE': setResultsAvailable ,
            'READY_TO_START': readyToStart 
        }
        
        readMessageFromRoom(gameRoomMessage, actionsByMessages)
    });
}