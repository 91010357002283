import { getProperties, protectClassHandler } from "./handlers.js";
import { GameStateSignal } from "./states.js";

class GradedWord {
    /** 
     * @param {string} word 
     * @param {boolean} valid 
     */
    constructor(word, valid) {
        this.valid = valid;
        this.word = word;
    }
}

export class GameResult {

    /**
     * @param {string} playerId 
     * @param {number} countValid
     * @param {Array} wordsFound 
     */
    constructor(playerId, wordsFound, countValid) {
        this.playerId = playerId;
        this.gradedWords = this.addWords(wordsFound);
        this.countValid = countValid;
    }
    
    /**
     * 
     * @param {Array<GradedWord>} wordsFound 
     * @returns {Array<GradedWord>}
     */
    addWords(wordsFound) {
        const result = [];
        for (const wordFound of wordsFound) {
            const [ word, valid ] = getProperties(['text', 'valid'], wordFound); 
            const newWordFound = new GradedWord(word, valid);
            result.push(newWordFound);
        }
        return result;
    }
}

export class GameResults {

    /**
     * @param {Array<GameResult>} gameResults 
     */
    constructor(gameResults) {
        this.results = gameResults;
    }

    /**
     * Contruct a GameResults from the @response param which comes directly from the API.
     * 
     * @param {Object} response 
     * @returns {GameResults}
     */
    static fromResponse(response) {
        const { results } = response;
        const gameResults = []
        for (const gameResult of results) {
            const [playerId, gradedWords, countValid] = getProperties(['playerId', 'gradedWords', 'countValid'], gameResult); 
            const newGameResult = new GameResult(playerId, gradedWords, countValid);
            gameResults.push(newGameResult);
        }
        return new GameResults(gameResults);
    }
}


export class DictionaryEntry {

    /**
     * Constructor
     * @param {string} word 
     * @param {string} posTitle 
     * @param {Array<String>} senses 
     */
    constructor(word, posTitle, senses) {
        this.word = word;
        this.posTitle = posTitle;
        this.definitions = senses;
    }
}

export class WordMatch {
    
    /**
     * Constructor
     * @param {string} word 
     * @param {Array<DictionaryEntry>} dictionaryEntries 
     */
    constructor(word, dictionaryEntries) {
        this.word = word;
        this.dictionaryEntries = dictionaryEntries;
    }
}

export class GameSolutions {

    /**
     * Constructor
     * @param {Array<WordMatch>} wordsFound 
     */
    constructor(wordsFound) {
        this.wordsFound = wordsFound
    }

    /**
     * 
     * @param {Object} gameResponseSolutions 
     * @returns {GameSolutions}
     */
    static fromResponse(gameResponseSolutions) {
        if (!gameResponseSolutions) { return };
        const wordsFound_dry = gameResponseSolutions.wordsFound;
        const wordsFound = [];
        for (const wordMatch_dry of wordsFound_dry) {
            const dictEntries = []
            for (const dictEntry of wordMatch_dry.dictionaryEntries) {
                const [word, posTitle, senses] = getProperties(['word', 'posTitle', 'definitions'], dictEntry); 
                const dictionaryEntry = new DictionaryEntry(word, posTitle, senses);
                dictEntries.push(dictionaryEntry);
            }
            const newWordMatch = new WordMatch(wordMatch_dry.word, dictEntries); 
            wordsFound.push(newWordMatch);
        }
        return new GameSolutions(wordsFound);
    }
}

export class GameConfig {

    /** @type {number} matrixSize */
    matrixSize;
    /** @type {number} minLengthWord */
    minLengthWord;
    /** @type {boolean} [multiplayer] */
    multiplayer;
    /** @type {string} [joinId] */
    joinId;
    /** @type {string} [playerId] */
    playerId;
    /** @type {number} duration */
    duration;
    /** @type {string} [recycleGame] */
    recycleGame;

    /**
     * Constructor
     * @param {Object} params
     * @param {number} params.matrixSize 
     * @param {number} params.minLengthWord 
     * @param {boolean}[params.multiplayer] 
     * @param {string} [params.joinId] 
     * @param {string} [params.playerId] 
     * @param {number} [params.duration]
     * @param {string} [params.recycleGame]
     */
    constructor({matrixSize, minLengthWord, multiplayer, joinId, playerId, duration, recycleGame}) {
        this.matrixSize = matrixSize;
        this.minLengthWord = minLengthWord;
        this.multiplayer = multiplayer;
        this.joinId = joinId;
        this.playerId = playerId;
        this.duration = duration;
        this.recycleGame = recycleGame;
    }
}

export class Game {
    
    /** @type {number} */
    matrixSize;
    /** @type {number} */
    minLengthWord;
    /** @type {Array<string>} */ 
    initialOrder;
    /** @type {Array<string>} */ 
    items;
    /** @type {Array<Array<Array<String>>>} */
    adjacentsMatrix;
    /** @type {string} */
    gameId;
    /** @type {string} */ 
    joinId;
    /** @type {boolean} */ 
    multiplayer;
    /** @type {GameSolutions} */
    solutions;
    /** @type {number} */
    duration;

    /**
     * Constructor
     * @param {Object} params
     * @param {number} params.matrixSize 
     * @param {number} params.minLengthWord 
     * @param {Array<string>} params.initialOrder 
     * @param {Array<string>} params.items 
     * @param {Array<Array<Array<String>>>} params.adjacentsMatrix 
     * @param {GameSolutions} [params.solutions] 
     * @param {string} params.gameId 
     * @param {string} [params.joinId] 
     * @param {boolean} [params.multiplayer] 
     * @param {number} [params.duration]
     */
    init({matrixSize, minLengthWord, initialOrder, items, adjacentsMatrix, solutions, gameId, joinId, multiplayer, duration}) {
        this.matrixSize = matrixSize;
        this.minLengthWord = minLengthWord;
        this.initialOrder = initialOrder;
        this.items = items;
        this.adjacentsMatrix = adjacentsMatrix;
        this.gameId = gameId;
        this.joinId = joinId;
        this.multiplayer = multiplayer;
        this.solutions = GameSolutions.fromResponse(solutions);
        this.duration = duration;
    }

    /**
     * 
     * @param {GameResponse} response 
     * @returns {Game}
     */
    static fromResponse(response) {
        const gameResponse = response.game;
        const newGame = new Game();
        const gameProxied = new Proxy(newGame, protectClassHandler);
        gameProxied.init(gameResponse);
        return gameProxied;
    }
}

export class GameResponse {
    
    /** @type object */
    game;
}

export class BoardItems {

    /**
     * Constructor
     * @param {Game} game 
     * @returns 
     */
    constructor(game){
        this.items = {};
        this.order = [];

        // const board = { items: {}, order: [] };
        const { matrixSize, items } = game;
    
        for (let i = 0; i < matrixSize; i++) {
            for (let j = 0; j < matrixSize; j++) {
                const coordinate = String(i) + String(j);
                const index = (matrixSize * i) + j
                this.order.push(coordinate);
                this.items[coordinate] = { coordinate, text: items[index], selected: false, selected_position: 0 };
            }
        }
    }
}

export class RoomMessage {
    /**
     * Constructor
     * @param {string} id 
     * @param {object} data 
     */
    constructor(id, data) {
        this.id = id;
        this.data = data;
    }
}

export class GameInstance {

    /**
     * Constructor
     * @param {GameStateSignal} gameStateSignals 
     * @param {Game} game 
     */
    constructor(gameStateSignals, game) {
        this.gameStateSignals = gameStateSignals;
        this.game = game;
    }
}

export class GameResultsByPlayer {

    /**
     * 
     * @param {GameResult} myPlayer 
     * @param {Array<GameResult>} otherPlayersResults 
     */
    constructor(myPlayer, otherPlayersResults) {
        this.myPlayer = myPlayer;
        this.othersPlayersResults = otherPlayersResults;
    }
}
