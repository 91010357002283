import { html } from "htm/preact"; 
import { boardRules } from "../services/boardRules.js";
import { messagesService } from "./FlashMessages.js";
import { useState } from "preact/hooks";
import { effect } from "@preact/signals-core";

const WordInProgress = (props) => {
    const { selectedBoardItems } = props;
    console.log('rendering <WordInProgress>');
    return html`<pre> ${selectedBoardItems.map(i => (i.text))} </pre>`
}

const AddWordButton = (props) => {
    const { selectedBoardItems } = props;
    const { gameInstance } = props;
    const { addWordCb } = props;
    const minLengthWord = gameInstance ? gameInstance.game.minLengthWord : 1;
    const addButtonDisbled = selectedBoardItems.length < minLengthWord ? 'true' : undefined;

    console.log('rendering <AddWordButton>');
   
    const addWord = () => {
        addWordCb();
    }

    return html`<button 
                    disabled=${addButtonDisbled} 
                    class="button add-word-action-button disabled-${addButtonDisbled}" 
                    onClick=${addWord}>    
                        <i class="fa-solid fa-plus"></i>
                </button>`
}

export const SelectionStatus = (props) => {
    const { gameInstance } = props;
    const game = gameInstance ? gameInstance.game : undefined;
    const gameStateSignals = game ? gameInstance.gameStateSignals : undefined;
    const selectedBoardItemsSignal = gameStateSignals ? gameStateSignals.getState('selectedBoardItems') : [];

    const [selectedBoardItems, setSelectedBoardItems ] = useState(selectedBoardItemsSignal);
 
    effect(() => {
        const newSelectedBoardItems = gameStateSignals.getState('selectedBoardItems');
        setSelectedBoardItems(newSelectedBoardItems);
    });
    
    console.log('rendering <SelectionStatus>');

    const addWord = () => {
        const updates = boardRules.addWord(gameInstance);
        if (updates.error && updates.error == 'DUPLICATED') { 
            messagesService.setMessage('Duplicated');
            return; 
        }
    }

    const cleanSelection = () => {
        boardRules.cleanSelection(gameInstance);
    }
    
    return html`
                <div class="selection-status">
                    <div class="remove-word-action">
                        <button class="button remove-word-action-button" onClick=${cleanSelection}>
                            <i class="fa-solid fa-trash"></i>
                        </button>
                    </div>
                    <div class="word-formed"> 
                        <${WordInProgress} selectedBoardItems=${selectedBoardItems}><//> 
                    </div> 
                    <div class="add-word-action">
                        <${AddWordButton} 
                            selectedBoardItems=${selectedBoardItems} 
                            gameInstance=${gameInstance}
                            addWordCb=${addWord}
                            ><//>
                    </div>
                </div>
            `
}

