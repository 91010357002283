import { GameStateSignal, RoundStateSignal } from "../models/states.js";
import { boardRules } from "./boardRules.js";
import { finishGame } from "./game.js";

export class MainBoardService {

    /**
     * Constructor
     * @param {RoundStateSignal} roundState 
     * @param {GameStateSignal} gameStateSignals 
     */
    constructor(roundState, gameStateSignals) {
        this.roundState = roundState;
        this.gameStateSignals = gameStateSignals;
    }

    async finishGame(gameInstance) {
        const gameType = this.roundState.getState('gameType');
        const originalWordList = this.gameStateSignals.getState('wordList');
        const playerId = this.roundState.getState('playerId');
        const { gameId } = gameInstance.game;
        boardRules.cleanSelection(gameInstance);

        const gameResults = await finishGame(gameType, gameId, playerId, originalWordList);
        if (gameType === 'singleplayer') {
            this.roundState.setState('gameResults', gameResults);
        }
        this.gameStateSignals.setState('finished', true);
        this.gameStateSignals.setState('active', false);
    }

}

