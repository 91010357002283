import { mainStates } from "../mainStates.js";
import { addMeToTheRoom, createNewGame } from "../game.js";


const requestNewMultiplayerGame = async (config) => {
    const roundState = mainStates.getRoundState();
    config.multiplayer = true;
    config.playerId = 'chango';
    const gameInstance = await createNewGame(config);
    const joinId = gameInstance.game.joinId;
    roundState.setState('gameInstance', gameInstance); // puede disparar el mainBoard , no ahora uso readyToStart
    roundState.setState('waitingForPlayers', 'started');
    await addMeToTheRoom({ joinId, playerId: 'lalo' }, roundState);
}

export const startMultiplayerService = { requestNewMultiplayerGame };