import { RoundStateSignal } from "../models/states.js";

export class StartGameService {

    /**
     * 
     * @param {RoundStateSignal} roundState 
     */
    constructor(roundState) {
        this.roundState = roundState;
    }

    stagesCompleted () {
        this.roundState.setState('stage', 'play');
    }

    /**
     * 
     * @param {String} gameType 
     * @param {String} gameTypeStage 
     */
    selectGameType (gameType, gameTypeStage) {
        this.roundState.setState('gameType', gameType);
        this.roundState.setState('stage', gameTypeStage);
    } 
}
