
import { html } from "htm/preact"; 

export const CoolButon = (props) => {
    const { classes, onClickCb, label } = props;

    const applyedClasses = ['button'].concat(classes).join(' ');
    return html`
            <mdui-button class=${applyedClasses} onClick=${onClickCb}>${label}</mdui-button>
            `
}
