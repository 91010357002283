import { html } from "htm/preact"; 
import { useState } from "preact/hooks";
import { SelectGame } from "./StartGame.js";
import { createRoundState } from "../services/game.js";
import { MainBoard } from "./MainBoard.js";
import { effect } from "@preact/signals-core";
import { mainStates } from "../services/mainStates.js";

const SubLanding = (props) => {
    const { roundState } = props;
    const [ currentStage, setCurrentStage ] = useState();

    console.log('rendering <SubLanding>');

    const showSelectGameType = () => {
        return html`<${SelectGame} roundState=${roundState}> <//>`
    }
    
    const showGameBoard = () => {
        const gameInstance = roundState.getState('gameInstance');
        return html`<${MainBoard} gameInstance=${gameInstance} roundState=${roundState}> <//>`
    }

    effect(() => {
        const stage = roundState.getState('stage');
        if (stage !== currentStage) {
            setCurrentStage(stage);
        }
        console.log('SubLanding stage changed ', stage);
    });

    const showBoardStage = currentStage === 'play' || currentStage === 'finished'; 

    return html`${ showBoardStage ? showGameBoard() : showSelectGameType() }`
}

/*
    roundState = { } 
    muesta la landing para elegir el tipo de juego y nada mas.

    muestra el mainboard con un game ya cargado y listo para jugar.
    roundState = { gameInstance: gameInstance }

*/

export const Landing = (props) => {
    const { debugSignal } = props;
    const newRoundState = debugSignal?.value || createRoundState();
   
    const [roundState, setRoundState] = useState(newRoundState);
    mainStates.setRoundState(newRoundState);

    const updateRoundState = (newState) => {
        setRoundState(newState);
        mainStates.setRoundState(newRoundState);
    }

    effect(() => {
        const newState = debugSignal?.value;
        if (newState) {
            updateRoundState(newState);
        }
    })

    console.log('rendering <Landing>');
    
    return html`
            ${ roundState ? html`<${SubLanding} roundState=${roundState}><//>` : undefined }
        `
}
