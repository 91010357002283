import { mainStates } from "../mainStates.js";
import { createNewGame } from "../game.js";

/**
 * 
 * @param {Object} config
 * @param {Function} stagesCompletedCb
 */
const requestNewGame = async (config, stagesCompletedCb) => {
    const roundState = mainStates.getRoundState();
    const gameInstance = await createNewGame(config);
    roundState.setState('gameInstance', gameInstance);
    stagesCompletedCb();
}


export const singlePlayerService = { requestNewGame }