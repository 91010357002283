import { useState } from "preact/hooks";
import { html } from "htm/preact"; 
import { GameSolutionsExplorer } from "./WordList/GameSolutions.js";
import { GameResult, GameResults } from "../models/models.js";
import { gameResultsService } from "../services/gameResultsService.js"
import { WordListService } from "../services/wordListService.js";
import { effect } from "@preact/signals-core";

/**
 * 
 * @param {Object} props
 * @param {GameResults} props.gameResults
 * @param {string} props.playerId
 * @param {Object} props.gameInstance
 * @returns 
 */
const ResultWordList = (props) => {
    const { gameResults, playerId, gameInstance } = props;    

    /**
     * 
     * @param {GameResult} gameResult 
     * @param {boolean} showPlayerId 
     * @returns 
     */
    const oneResult = (gameResult, showPlayerId) => {
        const { gradedWords } = gameResult;
        const currentPlayerId = gameResult.playerId;
        return html`
            <div class="game-result">
                ${ showPlayerId ? html`<div class="player-name">Player ${currentPlayerId} </div>` : undefined } 
                <div class="word-count"> 
                    <div class="total-words">
                        Total: ${gradedWords.length}  
                    </div>
                    <div class="total-words-valid">
                        Correct: ${gameResult.countValid}
                    </div>
                </div>
                ${gradedWords.map(w => ( html `<div class="word-item valid-${w.valid}"><pre>${w.word}</pre></div>`))}
            </div>
            `
    }

    const gameResultsByPlayer = gameResultsService.prepareGameResults(gameResults, playerId);

    return html`
        <div class="game-results">
            ${ oneResult(gameResultsByPlayer.myPlayer, false) }
            <div class="other-players-results">
                ${gameResultsByPlayer.othersPlayersResults.map(r => (oneResult(r, true)))}
            </div>
            <${GameSolutionsExplorer} gameInstance=${gameInstance}><//>
        </div>
        ` 
}

const WordsInProgress = (props) => {
    const { wordList } = props;

    return html`
        <Fragment>
            <div class="word-count"> words: ${wordList.length} </div>    
            ${wordList.map(w => ( 
                html `<div class="word-item"><pre>${w}</pre></div>`
            ))}
        </Fragment>`
}

export const WordList = (props) => {
    const { gameInstance, roundState } = props;
    const gameStateSignals = gameInstance ? gameInstance.gameStateSignals: undefined;
    const wordListSignal = gameStateSignals ? gameStateSignals.getState('wordList') : [];
    const [wordList, setWordList] = useState(wordListSignal);
    // /** @type {GameResults} */
    const [gameResults, setGameResults] = useState(roundState.getState('gameResults'));
    const playerId = roundState.getState('playerId');
    const wordListService = new WordListService(roundState);

    effect(() => {
        setWordList(gameStateSignals.getState('wordList'));
    });

    effect(() => {
        const newGameResults = roundState.getState('gameResults');
        setGameResults(newGameResults);
    });

    effect(() => {
        const gameRoomMessage = roundState.getState('gameRoomMessage')
        wordListService.readMessageFromRoom(gameRoomMessage, { messageId: 'RESULTS_AVAILABLE', action: wordListService.resultsAvailable })
    });

    return html`
        <div class="word-list-container">
            ${gameResults ?
                html`<${ResultWordList} gameInstance=${gameInstance} playerId=${playerId} gameResults=${gameResults}><//>`:
                html`<${WordsInProgress} wordList=${wordList}><//>`
            }
            </div>`
        }
