// import { Fragment } from "preact";
import { html } from "htm/preact"; 
import { useState } from "preact/hooks"
import { SinglePlayerStartStage, MultiplayerJoinStage, MultiplayerStartStage } from "./Stages/index.js";
import { CoolButon } from "./CoolButton.js"
import { StartGameService } from "../services/startGameService.js";

const SelectGameType = (props) => {
    const { selectGameTypeCb } = props;

    const selectGameType = (gameTypeSelected) => {
        const gameType = gameTypeSelected === 'startSingleplayer' ? 'singleplayer' : 'multiplayer';
        const gameTypeStage = gameTypeSelected;
        selectGameTypeCb(gameType, gameTypeStage);
    } 

    return html`<div>
            <div class="start-game-button-container">
                <${CoolButon} classes="${['start-new-game-button']}" label="Start new game" onClickCb=${() => selectGameType('startSingleplayer')}><//>
            </div>
            <div class="start-game-button-container">
                <${CoolButon} classes="${['start-new-game-button']}" label="Start new multiplayer game" onClickCb=${() => selectGameType('startMultiplayer')}><//>
            </div>
            <div class="start-game-button-container">
                <${CoolButon} classes="${['start-new-game-button']}" label="Join multiplayer" onClickCb=${() => selectGameType('joinMultiplayer')}><//>
            </div>
        </div>
       `
}

/*
    roundState = { gameType: undefined }
    muestra los 3 botones para elegir el tipo de juego.

    roundState = { gameType: 'singleplayer', stage: 'startSingleplayer' }
    muestra el <SinglePlayerStage>, donde se configura el board y se puede iniciar el juego single player (gameInstance)

    roundState = { gameType: 'multiplayer', stage: 'startMultiplayer' }
    muestra el <MultiplayerStartStage>, donde se configura el board y se puede iniciar un juego mutiplayer, esperar joins, etc.


*/
export const SelectGame = (props) => {
    const { roundState } = props;
    const stage = roundState.getState('stage');
    const [ stageSelected, setStageSelected ] = useState(stage);
    const startGameService = new StartGameService(roundState);

    const stagesCompletedCb = () => {
        roundState.setState('stage', 'play');
        // startGameService.stagesCompleted();
    }

    const selectGameTypeCb = (gameType, gameTypeStage) => {
        startGameService.selectGameType(gameType, gameTypeStage);
        setStageSelected(gameTypeStage);
    } 

    const cancelCb = () => { selectGameTypeCb() };    

    const gameTypeSelectedStage = () => {
        let component;
        switch (stage) {
            case 'startMultiplayer':
                component = MultiplayerStartStage;
                break;
            case 'joinMultiplayer':
                component = MultiplayerJoinStage;
                break;
            case 'startSingleplayer':
                component = SinglePlayerStartStage;
                break;
        }
        return html`<${component} 
                        cancelCb=${cancelCb} 
                        roundState=${roundState}
                        stagesCompletedCb=${stagesCompletedCb}
                        ><//>`
    }

    const selectGameTypeStage = () => {
        return html`<${SelectGameType} selectGameTypeCb=${selectGameTypeCb}><//>`
    }
    
    console.log('rendering <StartNewGame>');

    return html`
        <div class="start-new-game-container">
            ${ !stageSelected ? selectGameTypeStage() : gameTypeSelectedStage() } 
        </div>
        `;
    }