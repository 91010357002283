import { html } from "htm/preact"; 
import { signal } from "@preact/signals-core";


const createMessageService = () => {
    const message = signal('');

    const setMessage = (msg) => {
        message.value = msg;
        setTimeout(turnOffMessage, 2000);
    }

    const turnOffMessage = () => {
        message.value = '';
    }

    return { setMessage, message };
}

export const FlashMessages = (props) => {
    const { messagesService } = props;
    const message = messagesService.message;
    console.log('repainting <FlashMessages>');
    
    return html`<div> <pre class="flash-message"> ${message} </pre> </div>`   
}

export const messagesService = createMessageService();