const isAdjacent = (boardItem, selectedItems, adjacentsMatrix) => {
    const lastSelected = selectedItems[selectedItems.length -1];
    if (!lastSelected) { return true }
    
    const coordinateX = Number(lastSelected.coordinate[0]);
    const coordinateY = Number(lastSelected.coordinate[1]);
    const adjacents = adjacentsMatrix[coordinateX][coordinateY];
    return adjacents.find( s => boardItem.coordinate === s);
}

const getItemAsSelected = (boardItem, selectedItems) => {
    return { ...boardItem, 
        selected: true,
        selected_position: selectedItems.length + 1,
        canBeRemoved: true
    }
}

const getItemAsReseted = (boardItem) => {
    return { ...boardItem,
        selected: false,
        selected_position: 0,
        canBeRemoved: false
    }
}

// prepare the updated board item to reflect the change, now is a selected one
// update the selected collection with the addition of the selected board item
const insertSelectedBoardItem = (selectedBoardItems, boardItems, boardItem) => {
    const newBoardItems = { ...boardItems };

    const newItemState = getItemAsSelected(boardItem, selectedBoardItems);
    
    // update the board to reflect the item is now selected
    newBoardItems.items[boardItem.coordinate] = newItemState;
    // update the previous last one to reflect it cant be unselected because is not the last item anymore
    const last = selectedBoardItems[selectedBoardItems.length - 1];
    if (last) {
        newBoardItems.items[last.coordinate].canBeRemoved = false;
    }
    const newSelectedBoardItems = selectedBoardItems.concat(newItemState);
    return { newBoardItems, newSelectedBoardItems };
} 

const removeSelectedBoardItem = (selectedBoardItems, boardItems) => {
    const newBoardItems = { ...boardItems };
    // get a copy of the selected items collection
    // update the last item in the selected collection, now it can be unselected
    const newSelectedBoardItems = selectedBoardItems.slice();
    const removedItem = newSelectedBoardItems.pop();
    const lastSelected = newSelectedBoardItems[newSelectedBoardItems.length-1];
    if (lastSelected) { // not the last element in the selected array
        newBoardItems.items[lastSelected.coordinate].canBeRemoved = true;
    }
    // unselect the board item in the board
    newBoardItems.items[removedItem.coordinate] = getItemAsReseted(removedItem);
    return { newBoardItems, newSelectedBoardItems };
}
/**
 * El selectItem tiene que ejecutar el select, si es posible, mover el state del game , pero no hacer nada con el board
 * de signals. 
 * Quien mueve gameSignals ? Cada acción, como selectItem y finishGame? Tienen que devolver algo que es metido al
 * signal?
 */
const selectItem = (boardItem, gameInstance) => {
    const { gameStateSignals } = gameInstance;
    const selectedBoardItems = gameStateSignals.getState('selectedBoardItems');
    const boardItems = gameStateSignals.getState('boardItems');
    const { adjacentsMatrix } = gameInstance.game;
    let updates;
        
    if (! boardItem.selected) { // insert
        // check if the selectedItem is adjancent to the last selected board item
        if (!isAdjacent(boardItem, selectedBoardItems, adjacentsMatrix)) { return };
        updates = insertSelectedBoardItem(selectedBoardItems, boardItems, boardItem);
    } else { //remove
        updates = removeSelectedBoardItem(selectedBoardItems, boardItems);
    }
    // update the game.state
    gameStateSignals.setState('selectedBoardItems', updates.newSelectedBoardItems);
    gameStateSignals.setState('boardItems', updates.newBoardItems);
    return boardItem;
}

const cleanSelection = (gameInstance) => {
    const { gameStateSignals } = gameInstance;
    const selectedBoardItems = gameStateSignals.getState('selectedBoardItems');
    const boardItems = gameStateSignals.getState('boardItems');
    const cleanBoardItems = { ... boardItems.items };

    for (const item of selectedBoardItems) {
        cleanBoardItems[item.coordinate] = getItemAsReseted(item);
    }
    
    const newBoardItems = { ...boardItems, items: cleanBoardItems }
    gameStateSignals.setState('selectedBoardItems', []);
    gameStateSignals.setState('boardItems', newBoardItems);
}

const addWord = (gameInstance) => {
    const { gameStateSignals } = gameInstance;
    const selectedBoardItems = gameStateSignals.getState('selectedBoardItems'); 
    const wordList = gameStateSignals.getState('wordList'); 
    const word = (selectedBoardItems.map(item => (item.text))).join('');
    if (wordList.includes(word)) { return { error: 'DUPLICATED'} };
    cleanSelection(gameInstance);
    gameStateSignals.setState('wordList', wordList.concat(word));
    return { wordAdded: word }; 
}

export const boardRules = { selectItem, addWord, cleanSelection };