// import { render } from "preact";
import { Landing } from "./components/Landing.js";
import { html, render } from "htm/preact"; 
import { signal } from "@preact/signals-core";
import { Barrita } from "./debug/DebugBar.js";
import { sopaClientVersion } from "./version.js";
const debugSignal = signal();

document.addEventListener("DOMContentLoaded", function() {
    const bottom = document.getElementById('version');
    bottom.textContent = sopaClientVersion; 
}); 


const App = () => {

    const updateRoundState = (newState) => {
        debugSignal.value = newState;
    };
    
    const debugBar = () => {
        if (Barrita === undefined) {
            return ;
        }
        if (window.location.href.indexOf("debug") === -1) {
            return;
        }
        return html`
            <div class="dev-bar">
                <${Barrita} updateRoundState=${updateRoundState}><//>
            </div>`
    }

    return html`<App>
                ${debugBar()}
                <div class="center-screen main-container flexo-center-justify">
                    <${Landing} debugSignal=${debugSignal}><//>
                </div>
            </App>`;
    }

render(App(), document.getElementById('root'));