// import { render } from "preact";
import { Landing } from "./components/Landing.js";
import { html, render } from "htm/preact"; 

const App = () => {
    
    return html`<App>
                <div class="center-screen main-container flexo-center-justify">
                    <${Landing}><//>
                </div>
            </App>`;
    }

render(App(), document.getElementById('root'));