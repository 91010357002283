import { html } from "htm/preact"; 
import { useRef, useState } from "preact/hooks";
import { DictionaryEntry, GameSolutions, WordMatch } from "../../models/models.js";

export const WordDefinitions = (props) => {
    const { body, displayDefinitions, closeCb } = props;
    const dialogRef = useRef();
    const closeButtonRef = useRef();

    const closeDialog = () => {
        dialogRef.current.hide();
        closeCb();
    }

    const displayDefinition = (definition) => {
        return html`
            <div class="definition-wrapper flexo-center-justify margin-bottom-1em">
                <div class="icon">
                    <sl-icon name="asterisk"></sl-icon>
                </div>
                <div class="text">
                    ${definition}
                </div>
            </div>
        `
    }

    /**
     * 
     * @param {DictionaryEntry} dictionaryEntry 
     * @returns 
     */
    const displayEntry = (dictionaryEntry) => {
        return html`
            <div>
                <h3 class="capitalize">${dictionaryEntry.posTitle}</h3>
                ${dictionaryEntry.definitions.map( def => 
                    html`${displayDefinition(def)}`
                )}
            </div>
                `
    }

    /**
     * 
     * @param {WordMatch} wordMatch 
     * @returns 
     */
    const displayWordMatch = (wordMatch) => {
        let allEntries = [];
       
        // {"word":"tiende","dictionaryEntries":[
        //     {"word":"tiende",
        //         "definitions":[
        //             "Tercera persona del singular (ella, él, ello; usted, 2.ª persona) del presente de indicativo de tender o de tenderse."
        //             ,"Segunda persona del singular (tú) del imperativo afirmativo de tender."],"posTitle":"forma verbal"}
        //         ]
        //     }
        for (const entry of wordMatch.dictionaryEntries) {
            allEntries.push(displayEntry(entry));
        }

        return html`
            <div>
                <h3 class="capitalize">${wordMatch.word}</h3>
                <sl-divider></sl-divider>
                ${allEntries}
            </div>
            `
    }

    /**
     * 
     * @param {WordMatch} wordMatch 
     * @returns 
     */
    const dialog = (wordMatch) => {
        return html`    
            <sl-dialog open=${displayDefinitions} ref=${dialogRef} no-header="true" class="dialog-overview">
                ${displayWordMatch(wordMatch)}
                <sl-button onclick=${closeDialog} ref=${closeButtonRef} slot="footer" variant="primary">Close</sl-button>
            </sl-dialog>
        `;
    }
    return html`${dialog(body)}`
}

export const GameSolutionsExplorer = (props) => {
    const { gameInstance } = props;
    /** @type GameSolutions */
    const { wordsFound } = gameInstance.game.solutions;
    /**@type [boolean, function] */
    const [displayDefinitions, setDisplayDefinitions] = useState();
    const [wording, setWording] = useState();

    console.log('repainting <GameSolutionsExplorer>');

    const showWord = (event) => {
        event.preventDefault();
        const strIdx = event.target.getAttribute('data-idx');
        const idx = parseInt(strIdx);
        const word = wordsFound[idx];   
        setWording(word);
        setDisplayDefinitions(true);
    }

    const closeDialog = () => {
        setDisplayDefinitions(false);
    }

    return html`
        <div class="game-solutions">
            <div class="title"> Some words found (${wordsFound.length}) </div>
            ${ wordsFound.map((wordFound, idx) => html`
                <div class="word-item"> 
                    <a href="#" onclick=${showWord}><pre  data-idx=${idx}>${wordFound.word.toUpperCase()}</pre></a> 
                </div>`)
            }
            ${ displayDefinitions ? 
                html`<${WordDefinitions} closeCb=${closeDialog} displayDefinitions=${displayDefinitions} body=${wording}><//>`:
                undefined
            }
        </div>
    `
}