/** @module windowService */


/**
 * @param {Window} window 
 */
const backToLanding = (window) => {
    const parser = new URL(window.location);
    parser.searchParams.delete('gameId');
    window.location = parser.href;
}

/**
 * @param {Window} window 
 * @param {String} gameId 
 */
const recycleGame = (window, gameId) => {
    const parser = new URL(window.location);
    parser.searchParams.set('gameId', gameId);
    window.location = parser.href;
}

/**
 * 
 * @param {Window} window 
 * @returns String
 */
const isRecyledGame = (window) => {
    if (window.location.href.indexOf('gameId') == -1) { return }

    const params = new URLSearchParams(document.location.search);
    const gameId = params.get('gameId');
    return gameId;
}

/**
 * 
 * @param {Window} window 
 */
const cancelReplay = (window) => {
    const parser = new URL(window.location);
    parser.searchParams.delete('gameId');
    window.location = parser.href;
}

/**
 * {Object} windowService
 * {Function} windowService.backToLanding
 * {Function} windowService.recycleGame
 * {Function} windowService.isRecycledGame
 */
export const windowService = { backToLanding , recycleGame, isRecyledGame, cancelReplay };