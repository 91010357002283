import { effect } from "@preact/signals-core";
import { boardRules } from "../services/boardRules.js";
import { html } from "htm/preact"; 
import { useState } from "preact/hooks";

// Reescribir el Board y BoardItems manteniendo el signal privado a estos componentes, no debería
// hacer falta compartirlo a nivel global, o esa es la pregunta.
// En MainBoard/App va a crear los signals que hagan falta y mantener el estado del game.
// Tecnicamente, el estado también tiene que reflejar si hay items selecteds si nos ponemos finos...


const BoardItem = (props) => {
    const { game, item, selectItemCb, gameStateSignals } = props;
    const { matrixSize } = game;
    // let gameActive = gameStateSignals.getState('active');
    const [gameActive, setGameActive] = useState(gameStateSignals.getState('active'))

    console.log('rendering <BoardItem>');

    effect(() => {
        setGameActive(gameStateSignals.getState('active'));
    });

    const selectItem = () => {
        if (!gameActive) { return }
        if (item.selected && ! item.canBeRemoved) {
            return console.log('cant select item');
        }
        selectItemCb(item);
    }

    const classes = [
            'board-item',
            gameActive ? 'game-active' : 'game-finished',
            item.selected ? 'item-selected' : 'item-not-selected',
            item.canBeRemoved ? 'can-be-removed' : 'cant-be-removed' 
        ].join(' ');

    const styles = `width: ${ Math.floor(100 / matrixSize ) - 3}%`;

    return html`
        <div class="${classes}" style=${styles} onclick="${selectItem}"> 
            ${item.text}
            <small>${item.selected_position > 0 ? item.selected_position : undefined } </small>
        </div>
        `
}

export const Board = (props) => {
    const { gameInstance } = props;
    const { game } = gameInstance;
    const { initialOrder } = game;
    const { gameStateSignals } = gameInstance;

    const board = gameStateSignals.getState('boardItems');
    const [boardState, setBoardState] = useState(board);

    // it's replaced everytime a piece is selected. Could it be better to make a local copy of this boardItems?

    // const [ board, setBoard ] = useState(gameStateSignals.boardItems.value);
    // will it change every time the board is updated? How many re-renders?
    // setBoard(gameStateSignals.boardItems.value);
    // or should i use the signal directly without the state?
    // const boardItems = game.gameStateSignals.boardItems.value;
    // will it be updated when the change happen in the backgroud? Should it be a "effect?"
    
    // I need to manually update the signals in this callback.  
    console.log('rendering <Board>');

    const selectItemCb = (boardItem) => {
        boardRules.selectItem(boardItem, gameInstance);
    }

    effect(() =>{
        const newBoard = gameStateSignals.getState('boardItems'); 
        setBoardState(newBoard);
    });

    return html`
            <div class="board">
            ${initialOrder.map(orderIndex => ( 
                html`<${BoardItem} 
                        key=${orderIndex}
                        item=${boardState.items[orderIndex]}
                        selectItemCb=${selectItemCb}
                        gameStateSignals=${gameStateSignals}
                        game=${game}
                        ><//>`
                ))}      
        </div>
        `
}
