import { html } from "htm/preact"; 
import { useState } from "preact/hooks"
import { SinglePlayerStartStage, MultiplayerJoinStage, MultiplayerStartStage } from "./Stages/index.js";
import { CoolButon } from "./commons/CoolButton.js"
import { startGameService } from "../services/startGameService.js";
import { windowService } from "../services/windowService.js";

const SelectGameType = (props) => {
    const { selectGameTypeCb, roundState } = props;
    const [replayingGame , setReplayingGame ] = useState('');

    const selectGameType = (gameTypeSelected) => {
        const gameType = gameTypeSelected === 'startSingleplayer' ? 'singleplayer' : 'multiplayer';
        const gameTypeStage = gameTypeSelected;
        selectGameTypeCb(gameType, gameTypeStage, replayingGame);
    } 

    const gameId = windowService.isRecyledGame(window);
    if (gameId) {
        setReplayingGame(gameId);
        startGameService.setReplayGame(roundState, gameId);
    }

    const cancelReplay = () => {
        windowService.cancelReplay(window)
    }

    const labels = {
        startSinglePlayer: replayingGame ? 'Replay game': 'Start new game',
        startMultiplayer: replayingGame ? 'Replay multiplayer game': 'Start new multiplayer game',
        joinMultiplayerGame: replayingGame ? 'Replay joining the game': 'Join multiplayer'
    };

    const cancelReplayButton = () => {
        return html`
             <div class="start-game-button-container">
                <mdui-button variant="text" onclick=${cancelReplay} >Cancel replay</mdui-button>
            </div>`
    }

    const joinButton = () => {
        return html`
            <div class="start-game-button-container">
                <${CoolButon} classes="${['cy-join-multiplayer-game']}" label=${labels.joinMultiplayerGame} onClickCb=${() => selectGameType('joinMultiplayer')}><//>
            </div>` 
    }

    return html`
        <div>
            <div class="start-game-button-container">
                <${CoolButon} classes="${['cy-start-new-game']}" label=${labels.startSinglePlayer} onClickCb=${() => selectGameType('startSingleplayer')}><//>
            </div>
            <div class="start-game-button-container">
                <${CoolButon} classes="${['cy-start-new-multiplayer-game']}" label=${labels.startMultiplayer} onClickCb=${() => selectGameType('startMultiplayer')}><//>
            </div>
            ${  !replayingGame ? joinButton() : undefined }
            ${  replayingGame ? cancelReplayButton(): undefined }
        </div>
       `
}

/*
    roundState = { gameType: undefined }
    muestra los 3 botones para elegir el tipo de juego.

    roundState = { gameType: 'singleplayer', stage: 'startSingleplayer' }
    muestra el <SinglePlayerStage>, donde se configura el board y se puede iniciar el juego single player (gameInstance)

    roundState = { gameType: 'multiplayer', stage: 'startMultiplayer' }
    muestra el <MultiplayerStartStage>, donde se configura el board y se puede iniciar un juego mutiplayer, esperar joins, etc.


*/
export const SelectGame = (props) => {
    const { roundState } = props;
    const stage = roundState.getState('stage');
    const [ stageSelected, setStageSelected ] = useState(stage);

    const stagesCompletedCb = () => {
        startGameService.stagesCompleted(roundState);
    }

    const selectGameTypeCb = (gameType, gameTypeStage) => {
        startGameService.selectGameType(roundState, gameType, gameTypeStage);
        setStageSelected(gameTypeStage);
    } 

    const cancelCb = () => { selectGameTypeCb() };    

    const gameTypeSelectedStage = () => {
        let component;
        switch (stage) {
            case 'startMultiplayer':
                component = MultiplayerStartStage;
                break;
            case 'joinMultiplayer':
                component = MultiplayerJoinStage;
                break;
            case 'startSingleplayer':
                component = SinglePlayerStartStage;
                break;
        }
        return html`<${component} 
                        cancelCb=${cancelCb} 
                        roundState=${roundState}
                        stagesCompletedCb=${stagesCompletedCb}
                        ><//>`
    }

    const selectGameTypeStage = () => {
        return html`<${SelectGameType} roundState=${roundState} selectGameTypeCb=${selectGameTypeCb}><//>`
    }
    
    console.log('rendering <StartNewGame>');

    return html`
        <div class="start-new-game-container">
            ${ !stageSelected ? selectGameTypeStage() : gameTypeSelectedStage() } 
        </div>
        `;
    }