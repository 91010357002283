import { mainStates } from "../mainStates.js";
import { addMeToTheRoom, joinMultiplayerGame } from "../game.js";

const joinMultiplayer = async (config, joinId) => {
    const roundState = mainStates.getRoundState();
    config.multiplayer = true;
    config.joinId = joinId;
    config.playerId = 'Lauri';
    const { gameInstance } = await joinMultiplayerGame(config);
    roundState.setState('gameInstance', gameInstance);
    await addMeToTheRoom(config, roundState);
}

/**
 * 
 * @param {Function} stagesCompletedCb 
 */
const gameAboutToStartFinished = (stagesCompletedCb) => {
    const roundState = mainStates.getRoundState();
    roundState.setState('stage', 'play');
    stagesCompletedCb();
}

export const multiplayerJoinStageService = { joinMultiplayer, gameAboutToStartFinished }
