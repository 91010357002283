import { GameResults } from "../models/models.js";

const defaultGameResults = GameResults.fromResponse({ 
    results: [
            { 
                playerId: 'lalo',
                countValid: 8,
                gradedWords: [ {text: 'HOLA', valid: false},  {text: 'CHAU', valid: true}, {text: 'CHANGOS', valid: true},  {text: 'CHACHAHOLA', valid: false},  {text: 'CARCAZA', valid: true},  {text: 'TIBURON', valid: true}, {text: 'HOLA', valid: false},  {text: 'CHAU', valid: true}, {text: 'CHANGOS', valid: true},  {text: 'CHACHAHOLA', valid: false},  {text: 'CARCAZA', valid: true},  {text: 'TIBURON', valid: true} ]
            }
        ]
    });

// Stage - Choosing game
const singlePlayerStage = { gameType: 'singleplayer', stage: 'startSingleplayer' };
const startMultiplayerStage = { gameType: 'multiplayer', stage: 'startMultiplayer' };
const joinMultiplayerStage = { gameType: 'multiplayer', stage: 'joinMultiplayer' };


// Stage - waiting for players
const startMultiplayerWaiting = { gameType: 'multiplayer', stage: 'startMultiplayer', waitingForPlayers: 'started' };

// Stage - playing single
const singleplayerAboutToFinish = { gameType: 'singleplayer', stage: 'play' };
const joinMultiplayerStartedStage = { gameType: 'multiplayer', stage: 'play', players: ['seba', 'lauri'] };

// Stage - finished
const multiplayerFinished = { gameType: 'multiplayer', stage: 'finished', playerId: 'lalo', players: ['lauri'] };
const singleplayerFinished = { gameType: 'singleplayer', stage: 'finished', playerId: 'lalo', gameResults: defaultGameResults }

export const roundStatesByStageFixture = {
    starting: { singlePlayerStage, startMultiplayerStage, joinMultiplayerStage },
    waiting: { startMultiplayerWaiting },
    playing: { singleplayerAboutToFinish, joinMultiplayerStartedStage },
    finished: { multiplayerFinished }
}

 export const roundStatesFixtures = {
    singlePlayerStage, startMultiplayerStage, joinMultiplayerStage,
    startMultiplayerWaiting,
    singleplayerFinished, singleplayerAboutToFinish , joinMultiplayerStartedStage,
    multiplayerFinished
 } 
