import { Board } from "./Board.js";
import { html } from "htm/preact";
import { SelectionStatus } from "./SelectionStatus.js";
import { WordList } from "./WordList.js";
import { FlashMessages, messagesService } from "./FlashMessages.js";
import { CoolButon } from "./CoolButton.js";
import { MainBoardService } from "../services/mainBoardService.js";
import { useState } from "preact/hooks";

const MultiplayerStatus = (props) => {
    const { roundState } = props;
    const players = roundState.getState('players');


    return html`
        <div class="multiplayer-container flexo-vertical">
            <div> Players: </div>
            ${players.map( p => html`<label class="font-small"> ${p} </label>`)}
        </div>`
        ;
}

const FinishGameButton = (props) => {
    const { finishGameCb } = props;

    return html`
        <div class="flexo-center-justify padding-1em">
           <${CoolButon} classes="${[]}" label="Finish" onClickCb=${finishGameCb}><//>
        </div>
    `
}

export const MainBoard = (props) => {
    const { roundState, gameInstance } = props;
    const gameStateSignals = gameInstance ? gameInstance.gameStateSignals: undefined;
    const gameType = roundState.getState('gameType');
    const gameActive = gameStateSignals ? gameStateSignals.getState('active') : false;
    const mainBoardService = new MainBoardService(roundState, gameStateSignals);

    console.log('repainting <MainBoard>');

    const finishGameCb = async () => {
        await mainBoardService.finishGame(gameInstance)
    }

    return html`
            <Fragment>
                <div class="flash-messages-container">
                    <${FlashMessages} messagesService=${messagesService}><//>
                </div>  
                <div class="board-container">
                    ${gameInstance ? html`<${Board} gameInstance=${gameInstance} ><//>` : undefined}
                </div>
                <div class="selection-status-container">
                    <${SelectionStatus} gameInstance=${gameInstance}> <//> 
                </div>
                <div class="word-list-container">
                    <${WordList} roundState=${roundState} gameInstance=${gameInstance}> <//>
                </div>
                ${ gameActive ?
                    html`<${FinishGameButton} finishGameCb=${finishGameCb}><//>`:
                    undefined
                }
                ${ gameType === 'multiplayer' ? 
                    html`<${MultiplayerStatus} gameActive=${gameActive} roundState=${roundState}`:
                    undefined 
                }    
        </Fragment>`
}

const TimeLeft = (props) => {
    const { timeLeft } = props;
    return html`<div>${timeLeft}</div>`
}

const Timer = () => {
    const [timeLeft, setTimeLeft] = useState();
    
    const timeEnded = (timerFn) => {
        clearInterval(timerFn);
    }

    const startTimer = () => {
        let time = 3000;
        let timer = setInterval(() => {
            time = time - 1000;
            setTimeLeft(time);
            if (time === 0) { 
                timeEnded(timer) 
            }
        }, 1000);

    }

    console.log('repainting <Timer>');
    
    return html`<div> <${TimeLeft} timeLeft=${timeLeft} </div>`;
}
/*
<div class="flash-messages-container">
<${Timer} timeLeft=${timeLeft}><//>
</div>

    const startTimer = () => {
        let time = 300000;
        let timer = setInterval(() => {
            time = time - 1000;
            setTimeLeft(time);
            if (time === 0) { clearInterval(timer); }
        }, 1000);
    }
    
    const Timer = (props) => {
    const time = props.timeLeft;
    const [timeLeft, setTimeLeft] = useState();
    
    setTimeLeft(time);
    
    return html`<div> <pre> time left: ${timeLeft} </pre> </div>`;
}
*/