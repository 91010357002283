import { html } from "htm/preact";
import { useState } from "preact/hooks";
import { messagesService } from "../FlashMessages.js";
import { Loading } from "../commons/Loading.js";
import { ConfigGame } from "./ConfigGame.js";
import { GameConfig } from "../../models/models.js";
import { singlePlayerService } from "../../services/stages/singlePlayerService.js";

export const SinglePlayerStartStage = (props) => {
    const { cancelCb, stagesCompletedCb } = props;
    const [ waiting, setWaiting ] = useState(false);

    /**
     * Request a new game with the options selected in config
     * @param {GameConfig} configSelected 
     */
    const requestNewGame = async (configSelected) => {
        try {
            setWaiting(true);
            messagesService.setMessage('waiting ...')
            await singlePlayerService.requestNewGame(configSelected, stagesCompletedCb);
        } finally {
            setWaiting(false)
        }
    }

    return html`
        ${ waiting ?
            html`<${Loading}><//>`: 
            html`<${ConfigGame} configFinishedCb=${requestNewGame} cancelCb=${cancelCb}><//>`
        }
    `
}
