import { envs } from "../env.js";

import { Game, GameConfig, GameResponse, GameResults } from "../models/models.js";

/**
 * 
 * @param {number} ms 
 * @returns Promise
 */
const delay = ms => new Promise(res => setTimeout(res, ms));

export class GameNotFound extends Error {
    constructor(message) {
        super(message);
        this.name = 'Object not found';
    }
}

const createGameAPI = () => {
    const baseURL = envs.apiEndpointBaseUrl; //'http://192.168.1.99:8000';
    const baseWS = envs.wsEndpointBaseUrl; //'ws://192.168.1.99:8000/ws';
    const URLS = { 
        'newGame': '/game/new',
        'finishGame': (params) => (`/game/${params.gameId}/finish`),
        'finishMultiplayerGame': (params) => (`/game/multiplayer/${params.gameId}/finish`),
        'joinGame': '/game/request_join',
        'multiplayerStatus': (params) => (`/game/multiplayer/status/${params.gameId}`),
        'addMeToTheRoom': (params) => (`/game/multiplayer/join/${params.joinId}/${params.playerId}`)
    };

    const getUrl = (endPoint, urlParams, base) => {
        const domain = base === 'soquete' ? baseWS: baseURL;

        if (urlParams) {
            const urlFn = URLS[endPoint];
            const url = urlFn(urlParams);
            const finalUrl = `${domain}${url}`;
            return finalUrl;
        }
        const url = `${domain}${URLS[endPoint]}`
        return url; 
    } 

    const connectWS = (url) => {
        try {
            return new WebSocket(url);
        } catch (err) {
            console.error(err);
        }
    }

    const get = async (url) => {
        const response = await fetch(url,{
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                }
        });
        
        if (!response.ok) {
            throw new Error(`Something went wrong with the api ${url} - status: ${response.status}` );
        }
        return response.json()   
    }

    const post = async (url, body) => {
        const response = await fetch(url,{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                },
            body: JSON.stringify(body)
        });
        
        if (!response.ok) {
            
            if (response.status === '404') {
                throw new GameNotFound();
            }

            throw new Error(`Something went wrong with the api ${url} - status: ${response.status}` );
        }

        return response.json();
    }

    /**
     * Request a new game to the backend
     * @param {GameConfig} config 
     * @returns {Promise<Game>}
     */
    const newGame = async (config) => {
        const configRequest = { config };
        
        /** @type GameResponse */
        const response = await post(getUrl('newGame'), configRequest);
        
        /**  @type {Game} */
        const game = Game.fromResponse(response);
        
        return game;
    }

    const finishMultiplayerGame = async (gameFinishRequest) => {
        const { gameId } = gameFinishRequest; 
        const url = getUrl('finishMultiplayerGame', { gameId });
        const finishGameConfig = { config: gameFinishRequest }
        const response = await post(url, finishGameConfig);
        return response;
    }

    const finishGame = async (gameFinishRequest) => {
        const { gameId } = gameFinishRequest; 
        const url = getUrl('finishGame', { gameId });
        const finishGameConfig = { config: gameFinishRequest }
        const response = await post(url, finishGameConfig);
        const parsedResults = GameResults.fromResponse(response);
        return parsedResults;
    }

    const joinMultiplayerGame = async (config) => {
        const configRequest = { config };
            const response = await post(getUrl('joinGame'), configRequest);
            const { game } = response;
            return game;
    }

    const addMeToTheRoom = async (config) => {
        const params = { joinId: config.joinId, playerId: config.playerId };
        const url = getUrl('addMeToTheRoom', params ,'soquete');
        return connectWS(url);
    }

    return { newGame, joinMultiplayerGame, finishGame, finishMultiplayerGame, addMeToTheRoom };
}

export const gameAPI = createGameAPI()