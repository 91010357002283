import { html } from "htm/preact";
import  {  useState } from "preact/hooks";
import { effect } from "@preact/signals-core";
import { Loading } from "../commons/Loading.js";
import { ConfigGame } from "./ConfigGame.js";
import { startMultiplayerService } from "../../services/stages/startMultiplayerService.js";

/*
    roundState = { gameType: 'multiplayer', 'stage': 'startMultiplayer'}
    muestra el start button que dispara el waiting for players timer.

    roundState = { gameType: 'multiplayer', 'stage': 'startMultiplayer', 'waitingForPlayers': 'started' }
    debería mostrar un cancel button para frenar el timer que acaba de empezar de waitingforplayers.
    El gameInstance debería estar seteado también. Un tiempo de espera de 10 segundos, un timer también se muestra.
    Cada 500ms se chequea si hay otro player joined, durante 10s


    roundState = { gameType: 'multiplayer', 'stage': 'startMultiplayer', 'waitingForPlayers': 'finished' }
    Simula el tiempo de espera agotado, no se unión ningún player.
    El gameInstance debería estar seteado también.

    roundState = { gameType: 'multiplayer', 'stage': 'startMultiplayer', 'waitingForPlayers': 'started',
    players: [{ name: 'lauri'}], multiplayerReadyToStart: true }
    Si waitinfForPlayers es finished, no puede haber players o multiplayerREady.
    Si hay players, tiene que estar multiplayerReadyToStart en true. Puede depender del tamaño de players (computedFN ?)

    multiplayerAboutToStart = true ?

 */

export const MultiplayerStartStage = (props) => {
    const { cancelCb, roundState, stagesCompletedCb } = props;
    const waitingForPlayers = roundState.getState('waitingForPlayers'); //['started', 'finished'];
    const [ currentlyWaitingForPlayer, setCurrentlyWaitingForPlayers ] = useState(waitingForPlayers);
    const gameInstance = roundState.getState('gameInstance');
    const [waiting, setWaiting] = useState(false);

    console.log('repainting <MultiplayerStartStage>');


    effect(() => {
        setCurrentlyWaitingForPlayers(roundState.getState('waitingForPlayers'));
    });
    
    effect(() => {
        const gameAboutToStart = roundState.getState('gameAboutToStart');
        console.log('MultiplayerStage > gameAboutToStart ', gameAboutToStart);
        if (gameAboutToStart) { stagesCompletedCb() }
    });

    const requestNewMultiplayerGame = async (config) => {
        setWaiting(true);
        await startMultiplayerService.requestNewMultiplayerGame(config);
    }

    const showConfigGame = () => {
        return html `
            <${ConfigGame} 
                cancelCb=${cancelCb} 
                configFinishedCb=${requestNewMultiplayerGame}><//>`
    }

    const showWaitingForOtherPlayers = () => {
        if (currentlyWaitingForPlayer !== 'started') { return html`<${Loading}><//>`; }
        return html`
            <div class="multiplayer-container">
                <${MultiplayerWaitingForPlayers} gameInstance=${gameInstance}><//>
            </div>`
    }
 
    return html`
        <div class="game-type-selected-stage">
           ${ waiting ? 
                showWaitingForOtherPlayers():
                showConfigGame()
            }
            </div>`
        }

const MultiplayerWaitingForPlayers = (props) => {
    const { gameInstance } = props;
    const joinId = gameInstance.game.joinId;

    console.log('repainting <MultiplayerStatus>');

    return html
        `<div> 
            <pre> Game join id: <strong><span id="join-id-value"> ${joinId} </span></strong></pre>
            <pre> Waiting for Lauri </pre>
            <${Loading}><//>
        </div>`
}