export const protectClassHandler = {
    /**
     * 
     * @param {Object} target 
     * @param {string} prop 
     * @param {Object} receiver
     * @returns 
     */
    get(target, prop, receiver) {
        if (prop === 'then') return null; // I'm not a Thenable
        const exists = prop in target;
        if (!exists) {
            throw new Error(`Property <${prop}> not found in ${target.constructor.name}`);
        } 
        return target[prop];
    },
    /**
     * 
     * @param {Object} obj 
     * @param {string} prop 
     * @param {any} value 
     * @returns 
     */
    set(obj, prop, value) {
        const exists = prop in obj;
        if (!exists) {
            throw new Error(`Property <${prop}> not found in ${obj.constructor.name}`);
        } 
        obj[prop] = value;
        return true;
    }
}

/**
 * 
 * @param {string} propertyName 
 * @param {Object} valueHolder 
 * @returns 
 */
export const getProperty = (propertyName, valueHolder) => {
    const hasKey = propertyName in valueHolder; 
    if (!hasKey) {
        throw Error(`Property <${propertyName}> doesn't exists`)
    }
    return valueHolder[propertyName];
}

/**
 * 
 * @param {Array<string>} propertiesName 
 * @param {Object} valueHolder 
 * @returns {Array}
 */
export const getProperties = (propertiesName, valueHolder) => {
    const result = [];
    for (const propertyName of propertiesName) {
        result.push(getProperty(propertyName, valueHolder));
    }
    return result;
}

/**
 * 
 * @param {Array<string>} propertiesName 
 * @param {Object} valueHolder 
 * @returns {Object}
 */
export const getPropertiesObject = (propertiesName, valueHolder) => {
    const result = {};
    for (const propertyName of propertiesName) {
        result[propertyName] = getProperty(propertyName, valueHolder);
    }
    return result;
}
