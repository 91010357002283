import { RoundStateSignal } from "../models/states.js";
import { addMeToTheRoom, createNewGame, joinMultiplayerGame } from "./game.js";

export class SinglePlayerService {
    
    /**
     * Constructor
     * @param {RoundStateSignal} roundState 
     * @param {Function} stagesCompletedCb 
     */
    constructor(roundState, stagesCompletedCb) {
        this.roundState = roundState;
        this.stagesCompleted = stagesCompletedCb;
    }

    /**
     * 
     * @param {Object} config 
     */
    async requestNewGame (config) {
        const gameInstance = await createNewGame(config);
        this.roundState.setState('gameInstance', gameInstance);
        this.stagesCompleted();
    }

}


export class MultiplayerJoinStageService {
    /**
     * Constructor
     * @param {RoundStateSignal} roundState 
     * @param {Function} stagesCompletedCb 
     */
    constructor(roundState, stagesCompletedCb) {
        this.roundState = roundState;
        this.stagesCompletedCb = stagesCompletedCb;
    }

    /**
     * 
     * @param {Object} config 
     * @param {string} joinId 
     */
    async joinMultiplayer (config, joinId) {
        config.multiplayer = true;
        config.joinId = joinId;
        config.playerId = 'Lauri';
        const { gameInstance } = await joinMultiplayerGame(config);
        this.roundState.setState('gameInstance', gameInstance);
        await addMeToTheRoom(config, this.roundState);
    }
    
    multiplayerWaiting (multiplayerStatus) {
        this.roundState.setState('gameAboutToStart', true);
        this.roundState.setState('players', multiplayerStatus.players);
    }
    
    readMessageFromRoom (gameRoomMessage, { messageId, action }) {
        if (! gameRoomMessage ) { return };
        const { id, data } = gameRoomMessage; 
        if (id === messageId) {
            action(data);
        }
    }
    
    gameAboutToStartFinished () {
        this.roundState.setState('stage', 'play');
        this.stagesCompletedCb();
    }
    
}


export class MultiplayerStartService {

    /**
     * 
     * @param {RoundStateSignal} roundState 
     * @param {Function} stagesCompletedCb 
     */
    constructor(roundState, stagesCompletedCb) {
        this.roundState = roundState;
        this.randomID = 'chango';
        this.stagesCompleted = stagesCompletedCb;
    }


    async requestNewMultiplayerGame (config)  {
        config.multiplayer = true;
        config.playerId = this.randomID;
        const gameInstance = await createNewGame(config);
        const joinId = gameInstance.game.joinId;
        this.roundState.setState('gameInstance', gameInstance); // puede disparar el mainBoard , no ahora uso readyToStart
        this.roundState.setState('waitingForPlayers', 'started');
        await addMeToTheRoom({ joinId, playerId: 'lalo' }, this.roundState);
    }

    async multiplayerWatingCb (multiplayerStatus) {
        if (multiplayerStatus) {
            this.roundState.setState('waitingForPlayers', 'finished');
            this.roundState.setState('players', multiplayerStatus.players);
            this.roundState.setState('gameAboutToStart', true);
        } else {
            console.log('No players');
        }
    }

    gameAboutToStartFinishedCb () {
        // this.roundState.setState('stage', 'play');
        this.stagesCompleted();
    }

    readMessageFromRoom (gameRoomMessage, { messageId, action }) {
        if (! gameRoomMessage ) { return };
        console.log('<MultiplayerWaitingForPlayers> ws message ', gameRoomMessage);
        const { id, data } = gameRoomMessage; 
        if (id === messageId) {
            action(data);
        }
    }
    

}

