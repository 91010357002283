import { html } from "htm/preact";
import  { useState } from "preact/hooks";
import { effect } from "@preact/signals-core";
import { multiplayerJoinStageService } from "../../services/stages/joinMultiplayerService.js";
import { CoolButon } from "../commons/CoolButton.js";
import { Loading } from "../commons/Loading.js";


export const MultiplayerJoinStage = (props) => {
    const { cancelCb, roundState, stagesCompletedCb } = props;
    const [waiting, setWaiting] = useState(false);

    let joinId = '';
    const config = { matrixSize: 5, minLengthWord: 4};
    
    const onInput = (e) => {
        joinId = e.currentTarget.value
    }

    const joinMultiplayer = async () => {
        setWaiting(true);
        await multiplayerJoinStageService.joinMultiplayer(config, joinId);
    }

    effect(() => {
        const aboutToStart = roundState.getState('gameAboutToStart');
        if (aboutToStart) { gameAboutToStartFinishedCb(); }
    })

    const gameAboutToStartFinishedCb = () => {
        multiplayerJoinStageService.gameAboutToStartFinished(stagesCompletedCb);
    }

    const joinMultiplayerButton = () => {
        return html `
            <div>
                <pre> Joining id </pre>
                <input autofocus type="text" value=${joinId} onInput=${onInput} class="character-input cy-join-id-input" />
                <${CoolButon} classes="${['join-multiplayer-button, cy-join-multiplayer-button']}" label="Join" onClickCb=${joinMultiplayer}><//>
                <mdui-button variant="text" onclick=${cancelCb}>Cancel</mdui-button>
            </div>
            `
    }

    return html`
            <div class="game-type-selected-stage">
                ${ waiting ?
                    html`<${Loading}><//>`: 
                    joinMultiplayerButton()
                }
            </div>
            `
}


