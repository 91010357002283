import { html } from "htm/preact";
import { useEffect, useState } from "preact/hooks";

const GameAboutToStartTimer = (props) => {
    const { timeLeft } = props;
    return html`<h3> Starting in ${timeLeft} </h3>`
}


export const GameAboutToStart = (props) => {
    const { gameAboutToStartFinishedCb } = props;
    const [timeLeft, setTimeLeft] = useState();
    let timer;
    let gameAboutToStartTimeLeft = 1000;
    // let finishPoll = false;

    const timeEnded = () => {
        clearInterval(timer);
        gameAboutToStartFinishedCb();
    }

    const displayTimeLeft = () => {
        // if (finishPoll) { return };
        gameAboutToStartTimeLeft = gameAboutToStartTimeLeft - 1000;
        if (gameAboutToStartTimeLeft === 0) { 
            timeEnded(); 
        } else {
            setTimeLeft(gameAboutToStartTimeLeft);
        }
    }

    const startTimer = () => {
        timer = setInterval( displayTimeLeft , 1000);
    }

    console.log('repainting <GameAboutToStart>');

    useEffect(() => {
        startTimer();
    }, []);

    return html
        `<div> 
            <${GameAboutToStartTimer} timeLeft=${timeLeft}> <//>
        </div>`
}

