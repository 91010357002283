import { html } from "htm/preact";
import { useState } from "preact/hooks";
import { messagesService } from "../FlashMessages.js";
import { SinglePlayerService } from "../../services/stagesService.js";

const Loading = () => {
    return html`
        <div class="flexo-center-justify"> 
            <img src="assets/images/waiting_players.gif"> </img>
        </div>`
}

export const SinglePlayerStartStage = (props) => {
    const { cancelCb, roundState, stagesCompletedCb } = props;
    const [ waiting, setWaiting ] = useState(false); 
    const config = { matrixSize: 5, minLengthWord: 4};
    const singlePlayerService = new SinglePlayerService(roundState, stagesCompletedCb);

    const requestNewGame = async () => {
        try {
            setWaiting(true);
            messagesService.setMessage('waiting ...')
            await singlePlayerService.requestNewGame(config);
        } finally {
            setWaiting(false)
        }
    }

    const startButton = () => {
        return html`
            <button onClick=${requestNewGame} role="button" class="button-30 join-multiplayer-button">
                Start
            </button>
            <a href="#" onClick=${cancelCb}>Cancel</a>
        `
    }

    return html`
        <div class="game-type-selected-stage"> 
            <pre> Maybe choose an option </pre>
            <div>
                ${ waiting ?
                    html`<${Loading}><//>`: 
                    startButton()
                }
            </div>
        </div>
    `
}