import { RoundStateSignal } from "../models/states.js";

export class WordListService {

    /**
     * Constructor
     * @param {RoundStateSignal} roundState 
     */
    constructor(roundState) {
        this.roundState = roundState;
    }

    resultsAvailable(results) {
        this.roundState.setState('gameResults', results);
    }

    readMessageFromRoom (gameRoomMessage, { messageId, action }) {
        if (! gameRoomMessage ) { return };
        console.log('<WordList> ws message ', gameRoomMessage);
        const { id, data } = gameRoomMessage; 
        if (id === messageId) {
            action(data);
        }
    }

}
