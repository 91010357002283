import { Board } from "./Board.js";
import { html } from "htm/preact";
import { MainActions } from "./SelectionStatus.js";
import { WordList } from "./WordList/WordList.js";
import { FlashMessages, messagesService } from "./FlashMessages.js";
import { mainBoardService } from "../services/mainBoardService.js";
import { CountDown } from "./commons/Countdown.js";
import { RoundStateSignal } from "../models/states.js";
import { GameInstance } from "../models/models.js";

/**
 * @import { Dispatch } from "preact/hooks" 
 */

const MultiplayerStatus = (props) => {
    const { roundState } = props;
    const players = roundState.getState('players');


    return html`
        <div class="multiplayer-container flexo-vertical">
            <div> Players: </div>
            ${players.map( p => html`<label class="font-small"> ${p} </label>`)}
        </div>`
        ;
}


/**
 * 
 * @param {Object} props
 * @param {GameInstance} props.gameInstance 
 */
const MainBoardHeader = (props) => {
    const { gameInstance } = props;
    const { game } = gameInstance;

    const timeLeft = game.duration;

    const finishCountdown = () => {
        mainBoardService.finishGame();
        // console.log('MainBoardHeader finish countdown');
    }

    return html
            `   <div class="main-board-header">
                    <div class="flash-messages-container">
                        <${FlashMessages} messagesService=${messagesService}><//>
                    </div>
                    <div class="countdown-container">
                        <${CountDown} timeToCount=${timeLeft} callback="${finishCountdown}"><//>
                    </div>
                </div>  
            `;
}

/**
 * @param {Object} props 
 * @param {RoundStateSignal} props.roundState
 * @param {GameInstance} props.gameInstance
 */
export const MainBoard = (props) => {
    const { roundState, gameInstance } = props;
    const gameStateSignals = gameInstance ? gameInstance.gameStateSignals: undefined;
    const gameType = roundState.getState('gameType');
    const gameActive = gameStateSignals ? gameStateSignals.getState('active') : false;

    console.log('repainting <MainBoard>');

    return html`
            <div class="landing-container">
                <${MainBoardHeader} gameInstance=${gameInstance}><//>
                <div class="board-container">
                    ${gameInstance ? html`<${Board} gameInstance=${gameInstance} ><//>` : undefined}
                </div>
                <div class="selection-status-container">
                    <${MainActions} gameStateSignals=${gameStateSignals} gameInstance=${gameInstance}> <//> 
                </div>
                <div class="word-list-container">
                    <${WordList} roundState=${roundState} gameInstance=${gameInstance}> <//>
                </div>
                ${ gameType === 'multiplayer' ? 
                    html`<${MultiplayerStatus} gameActive=${gameActive} roundState=${roundState}`:
                    undefined 
                }    
        </div>`
}
