import { html } from "htm/preact";

export const RadioOptions = (props) => {
    const { groupLabel , options, optionSelectedCb, defaulOption } = props;

    const selectOption = (event) => {
        console.log(event.target.value);
        optionSelectedCb(event.target.value);
    }

    return html`
        <mdui-radio-group name="${groupLabel}" value="${defaulOption.value}">
            ${ options.map(option => (
                html `<mdui-radio value="${option.value}" onclick=${selectOption}>${option.label}</mdui-radio>`
            ))}
        </mdui-radio-group>        
    `;
}

