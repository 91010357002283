import { html } from "htm/preact"; 
import { useRef, useState } from "preact/hooks";
import { DictionaryEntry, GameSolutions, WordMatch } from "../../models/models.js";

export const WordDefinitions = (props) => {
    const { body, displayDefinitions, closeCb } = props;
    const dialogRef = useRef();
    const closeButtonRef = useRef();

    const closeDialog = () => {
        closeCb();
    }

    const displayDefinition = (definition) => {
        return html`
            <div class="definition-wrapper flexo-center-justify margin-bottom-1em">
                <div class="icon">
                    <i class="fa-solid fa-asterisk"></i>
                </div>
                <div class="text">
                    ${definition}
                </div>
            </div>
        `
    }

    /**
     * 
     * @param {DictionaryEntry} dictionaryEntry 
     * @returns 
     */
    const displayEntry = (dictionaryEntry) => {
        return html`
            <div>
                <h4 class="capitalize">${dictionaryEntry.posTitle}</h4>
                ${dictionaryEntry.definitions.map( def => 
                    html`${displayDefinition(def)}`
                )}
            </div>
                `
    }

    /**
     * 
     * @param {WordMatch} wordMatch 
     * @returns 
     */
    const displayWordMatch = (wordMatch) => {
        let allEntries = [];
       
        // {"word":"tiende","dictionaryEntries":[
        //     {"word":"tiende",
        //         "definitions":[
        //             "Tercera persona del singular (ella, él, ello; usted, 2.ª persona) del presente de indicativo de tender o de tenderse."
        //             ,"Segunda persona del singular (tú) del imperativo afirmativo de tender."],"posTitle":"forma verbal"}
        //         ]
        //     }
        for (const entry of wordMatch.dictionaryEntries) {
            allEntries.push(displayEntry(entry));
        }

        return html`
            <div class="mdui-prose">
                <mdui-divider></mdui-divider>
                ${allEntries}
            </div>
            `
    }

    /**
     * 
     * @param {WordMatch} wordMatch 
     * @returns 
     */
  
    console.log('<GameSolutions> repaiting ');
    
    const dialogMuid = (wordMatch) => {
        return html `
            <mdui-dialog ref="${dialogRef}" open="true">
            <mdui-top-app-bar slot="header">
                <mdui-top-app-bar-title class="capitalize">${wordMatch.word}
                    </mdui-top-app-bar-title>
                </mdui-top-app-bar>
                ${displayWordMatch(wordMatch)}
                <mdui-divider class="margin-bottom-2em"></mdui-divider>
                <mdui-button onclick=${closeDialog} ref=${closeButtonRef}>Close</mdui-button>
            </mdui-dialog>
        `;

    }

    return html`${ displayDefinitions ? dialogMuid(body): undefined }`
}

export const GameSolutionsExplorer = (props) => {
    const { gameInstance } = props;
    /** @type GameSolutions */
    const { wordsFound } = gameInstance.game.solutions;
    /**@type [boolean, function] */
    const [displayDefinitions, setDisplayDefinitions] = useState(false);
    const [wording, setWording] = useState();

    console.log('repainting <GameSolutionsExplorer>');

    const showWord = (event) => {
        event.preventDefault();
        const strIdx = event.target.getAttribute('data-idx');
        const idx = parseInt(strIdx);
        const word = wordsFound[idx];   
        setWording(word);
        setDisplayDefinitions(true);
    }


    const closeDialog = () => {
        setDisplayDefinitions(false);
    }

    return html`
        <div class="game-solutions">
            <div class="title"> Some words found (${wordsFound.length}) </div>
            <mdui-list>
            ${ wordsFound.map((wordFound, idx) => html`
                <mdui-list-item onclick=${showWord} data-idx=${idx}>${wordFound.word.toUpperCase()}</mdui-list-item>
            `)}
            </mdui-list>
            <${WordDefinitions} closeCb=${closeDialog} displayDefinitions=${displayDefinitions} body=${wording}><//>
        </div>
        `
    }