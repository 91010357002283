import { html } from "htm/preact"; 
import { boardRules } from "../services/boardRules.js";
import { messagesService } from "./FlashMessages.js";
import { useState } from "preact/hooks";
import { effect } from "@preact/signals-core";
import { CoolButon } from "./commons/CoolButton.js";
import { windowService } from "../services/windowService.js";

const SelectedLetters = (props) => {
    const { selectedBoardItems } = props;
    console.log('rendering <SelectedLetters>');
    return html`<pre> ${selectedBoardItems.map(i => (i.text))} </pre>`
}

const AddWordButton = (props) => {
    const { selectedBoardItems } = props;
    const { gameInstance } = props;
    const { addWordCb } = props;
    const minLengthWord = gameInstance ? gameInstance.game.minLengthWord : 1;
    const addButtonDisbled = selectedBoardItems.length < minLengthWord ? 'true' : undefined;

    console.log('rendering <AddWordButton>');
   
    const addWord = () => {
        addWordCb();
    }

    return html`<mdui-button-icon disabled=${addButtonDisbled} 
                    variant="tonal" 
                    onClick=${addWord}
                    class="button add-word-action-button cy-add-word-action-button disabled-${addButtonDisbled}">
                        <i class="fa-solid fa-plus"></i>
                </mdui-button-icon>`
  
}

export const WordInProgress = (props) => {
    const { gameInstance } = props;
    const game = gameInstance ? gameInstance.game : undefined;
    const gameStateSignals = game ? gameInstance.gameStateSignals : undefined;
    const selectedBoardItemsSignal = gameStateSignals ? gameStateSignals.getState('selectedBoardItems') : [];

    const [selectedBoardItems, setSelectedBoardItems ] = useState(selectedBoardItemsSignal);
 
    effect(() => {
        const newSelectedBoardItems = gameStateSignals.getState('selectedBoardItems');
        setSelectedBoardItems(newSelectedBoardItems);
    });
    
    console.log('rendering <SelectionStatus>');

    const addWord = () => {
        const updates = boardRules.addWord(gameInstance);
        if (updates.error && updates.error == 'DUPLICATED') { 
            messagesService.setMessage('Duplicated');
            return; 
        }
    }

    const cleanSelection = () => {
        boardRules.cleanSelection(gameInstance);
    }
    
   
    return html`
                <div class="selection-status">
                    <div class="remove-word-action">
                        <mdui-button-icon variant="tonal" onClick=${cleanSelection}>
                            <i class="fa-solid fa-xmark"></i>
                        </mdui-button-icon>
                        
                    </div>
                    <div class="word-formed"> 
                        <${SelectedLetters} selectedBoardItems=${selectedBoardItems}><//> 
                    </div> 
                    <div class="add-word-action">
                        <${AddWordButton} 
                            selectedBoardItems=${selectedBoardItems} 
                            gameInstance=${gameInstance}
                            addWordCb=${addWord}
                            ><//>
                    </div>
                </div>
            `
}

const GameFinishedMainAction = (props) => {
    const { gameInstance } = props;

    const backToLanding = () => {
        windowService.backToLanding(window);
    }

    const recycleGame = () => {
        const gameId = gameInstance.game.gameId;
        windowService.recycleGame(window, gameId);
    }

    return html `
        <div>
            <${CoolButon} label="Back" onClickCb=${backToLanding} classes="${[]}"><//>
            <${CoolButon} label="Recycle" onClickCb=${recycleGame} classes="${[]}"><//>
        </div>`
}

export const MainActions = (props) => {
    const { gameInstance, gameStateSignals } = props;
    const [gameActive, setGameActive] = useState(gameStateSignals.getState('active'))

    console.log('rendering <MainActions>');

    effect(() => {
        setGameActive(gameStateSignals.getState('active'));
    });

    return html`
        <div class="main-actions">
            ${ gameActive ? 
                html `<${WordInProgress} gameInstance=${gameInstance}> <//>`:
                html `<${GameFinishedMainAction} gameInstance=${gameInstance}><//>`
            }
        </div>
    `;
}