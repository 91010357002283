import { boardRules } from "./boardRules.js";
import { finishGame } from "./game.js";
import { updateResults } from "./gameResultsService.js";
import { mainStates } from "./mainStates.js";

export const mainBoardService = {
    finishGame: async () => {
        const gameInstance = mainStates.getGameInstance();
        const roundState = mainStates.getRoundState();
        const gameStateSignals = mainStates.getGameStateSignals();

        const gameType = roundState.getState('gameType');
        const originalWordList = gameStateSignals.getState('wordList');
        const playerId = roundState.getState('playerId');
        const { game } = gameInstance;
        boardRules.cleanSelection(gameInstance);

        const gameResults = await finishGame(gameType, game, playerId, originalWordList);
        updateResults(roundState, gameResults, gameType);
        gameStateSignals.setState('finished', true);
        gameStateSignals.setState('active', false);
    }
}