
import { html } from "htm/preact"; 

export const CoolButon = (props) => {
    const { classes, onClickCb, label } = props;

    const applyedClasses = [ 'button-30' ].concat(classes).join(' ');
    return html`
            <button onClick=${onClickCb} role="button" class="${applyedClasses}">
                ${label} 
            </button>`
}