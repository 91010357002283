import { GameResults, GameResultsByPlayer, GameSolutions } from "../models/models.js";
import { GameType, RoundStateSignal } from "../models/states.js";
import { updateGameSolutions } from "./game.js";


export const setResultsAvailable = (roundState, data) => {
    console.log('mainStates > setResultsAvailable');
    const results = GameResults.fromResponse(data);
    const solutions = GameSolutions.fromResponse(data.solutions);
    const gameInstance = roundState.getState('gameInstance');
    updateGameSolutions(gameInstance.game, solutions)
    roundState.setState('gameResults', results);
}

/**
 * 
 * @param {RoundStateSignal} roundState 
 * @param {GameResults} results 
 * @param {String} gameType 
 */
export const updateResults = (roundState, results, gameType) => {
    if (gameType === GameType.SINGLE_PLAYER) {
        roundState.setState('gameResults', results);
    }
}

/**
 * Separate the results based on the player (myPlayer) and counts how many words are graded as valid
 * There are two separate views for the results, one for the current player and the other for the rest in case
 * of multiplayer.
 * @param {GameResults} gameResults 
 * @param {string} playerId 
 * @returns {GameResultsByPlayer}
 */
const prepareGameResults = (gameResults, playerId) => {
    const otherPlayersResults = [];
    let myPlayer;
    for (const result of gameResults.results) {
        if (result.playerId == playerId ) {
            myPlayer = result;
        } else {
            otherPlayersResults.push(result);
        }
    }

    return new GameResultsByPlayer(myPlayer, otherPlayersResults);
}

export const gameResultsService = { prepareGameResults };