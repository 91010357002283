import { html } from "htm/preact";
import { useEffect, useRef, useState } from "preact/hooks";
/** @import { Dispatch } from "preact/hooks" */

const DisplayTimeLeft = (props) => {
    const { timeLeft } = props;
    return html`<div> ${timeLeft} </div>`
}

/**
 * 
 * @param {number} timer 
 */
const formatTime = (timer) => {
    let minutes = Math.floor(timer / 60);
    let seconds = Math.floor(timer % 60);

    const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
    const secondsStr = seconds < 10 ? `0${seconds}` : seconds;

    const time = `${minutesStr}:${secondsStr}`; 
    return time;
}

export const CountDown = (props) => {
    const { callback, timeToCount } = props;
    /** @type [string, Dispatch<string>] */
    const [timeLeftState, setTimeLeftState] = useState();
    const snackRef = useRef();
    /**@type number */
    let timeLeft;
    let timerId;
    
    const timeEnded = () => {
        snackRef.current.open = true;
        clearInterval(timerId);
        callback();
    }

    const tick = () => {
        timeLeft = timeLeft - 1;
        // timeLeft = newTime;
        setTimeLeftState(formatTime(timeLeft));
        if (timeLeft <= 0) { 
            timeEnded(); 
        }
    }

    const startTimer = () => {
        timerId = setInterval(tick, 1000);
    }

    console.log('repainting <CountDown>');

    useEffect(() => {
        timeLeft = timeToCount;
        setTimeLeftState(formatTime(timeToCount));
        startTimer();
    }, []);

    return html
        `<div class="countdown-container">
            <mdui-snackbar ref=${snackRef} auto-close-delay="2500" message-line="2" placement="top">
                <div>
                    Game 
                    Finished! <span> </span>
                    <i class="fa-solid fa-stopwatch"></i>
                </div>
            </mdui-snackbar>
            <${DisplayTimeLeft} timeLeft=${timeLeftState}> <//>
        </div>`
}

