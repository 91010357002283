
import { BoardItems, Game, GameConfig, GameInstance, GameSolutions } from "../models/models.js";
import { GameStateSignal, GameType, RoundStateSignal } from "../models/states.js";
import { gameAPI, GameNotFound } from "./gameAPI.js";


export const finishGame = async (gameType, game, playerId, wordList) => {
    const wordsLowercase = wordList.map(w => (w.toLowerCase()));
    const finishGameRequest = { gameId: game.gameId, playerId, wordList: wordsLowercase }
    if (gameType === GameType.MULTIPLAYER ) {
        await gameAPI.finishMultiplayerGame(finishGameRequest);
    } else {
        const gameResultasAndSolutions = await gameAPI.finishGame(finishGameRequest);
        updateGameSolutions(game, gameResultasAndSolutions.solutions);
        return gameResultasAndSolutions.results;
    }
}

/** This function exists to keep the modifications of the game object in one place.
 * In the mainStates service also needs to modify the game object, so, its invoking this function, without
 * incurring in circular dependencies.
 * @param {Game} game
 * @param {GameSolutions} solutions 
 */
export const updateGameSolutions = (game, solutions) => {
    game.solutions = solutions
}

/**
 * 
 * @param {Game} game 
 * @returns {BoardItems}
 */
const createBoard = (game) => {
    return new BoardItems(game);
}


/**
 * 
 * @returns {RoundStateSignal}
 */
export const createRoundState = () => {
    /** @type {RoundStateSignal} */
    const newRoundState = new RoundStateSignal()
    return newRoundState;
}

/**
 * 
 * @param {Game} game 
 * @returns {GameStateSignal}
 */
export const createGameState = (game) => {
    const boardItems = createBoard(game);
    return new GameStateSignal(boardItems);
}

/**
 * If the Game exists, it comes from the multiplayerJoin
 * @param {GameConfig} [config] 
 * @param {Object} [gameResponse] 
 * @returns {Promise<GameInstance>}
 */
export const createNewGame = async (config, gameResponse) => {
    let newGame;
    if (gameResponse) { 
        newGame = gameResponse; 
    } else { 
        newGame = await gameAPI.newGame(config); 
    }

    // newGame.solutions = GameSolutions.fromResponse(newGame.solutions)
    const gameStateSignals = createGameState(newGame);
    const gameInstance = new GameInstance(gameStateSignals, newGame);
    return gameInstance;
    // return { gameStateSignals, game: newGame };
}

export const joinMultiplayerGame = async (config) => {
    try {
        const game = await gameAPI.joinMultiplayerGame(config);
        const newGame = await createNewGame(config, game);
        return { gameInstance: newGame };
    } catch (err) {
        if (err instanceof GameNotFound) {
            throw err; // should throw a better exception to be handled by the toast
        }
    }
}

export const addMeToTheRoom = async (config, roundState) => {
    const gameRoomWs = await gameAPI.addMeToTheRoom(config);
    
    const handleEvents = (event) => {
        console.log(event);
        const dataJson = JSON.parse(event.data);
        switch (dataJson.id) {
            case 'READY_TO_START':
                roundState.setState('gameRoomMessage', { id: 'READY_TO_START', data: { players: dataJson.data.players }});
                break;
            case 'PLAYER_JOINED':
                roundState.setState('gameRoomMessage', { id: 'PLAYER_JOINED', data: { players: dataJson.data.players }});
                break;
            case 'RESULTS_AVAILABLE':
                roundState.setState('gameRoomMessage', { id: 'RESULTS_AVAILABLE', data: dataJson.data });
                break;
            case 'END_MULTIPLAYER_GAME':
                roundState.setState('gameRoomMessage', { id: 'END_MULTIPLAYER_GAME' });
                break;
        }
    }
    gameRoomWs.onmessage = handleEvents;
    gameRoomWs.onclose = () => { console.log('ws closed') };

    await new Promise( (resolve, reject) => {
        gameRoomWs.onopen = () => {
            console.log('ws open')
            resolve();
        }
    });
}